import React from 'react';

import Column from '@models/components/data/column';
import moment from 'moment';
import OverflowTip from '@components/OverflowTip';
import Box from '@material-ui/core/Box';
import { EnvelopeGrid } from '@src/models/stores/signatures/envelope-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { People } from '@material-ui/icons';
import { EnvelopeChip } from '../style';
import { envelopeColors } from '../models/color';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { sendAnalytics } from '@utils/analytics';
import { EVENTS } from '../constants/analytics';
import IconButton from '@material-ui/core/IconButton';

const SimpleText = (props) => {
  return (
    (
      <OverflowTip
        variant="body2"
        customStyle={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.8)' }}
        onClick={props.onClick}
        value={props.children}
        noWrap
      />
    ) || <div>-</div>
  );
};

export const envelopeColumns = () => {
  const columnsGroup: Column<EnvelopeGrid>[] = [
    {
      label: '',
      accessor: 'hasMultipleSignatures',
      active: true,
      bodyCellProps: {
        style: {
          whiteSpace: 'nowrap',
          padding: '16px 0 16px 16px',
        },
      },
      headCellProps: {
        style: {
          width: '10px',
          fontWeight: 600,
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
      component: (props) => {
        return (
          <Box>
            {props.children && (
              <Tooltip title="Múltiplos Signatários">
                <People color="primary" />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      label: 'ENVELOPE',
      accessor: 'id',
      active: true,
      bodyCellProps: {
        style: {
          whiteSpace: 'nowrap',
        },
      },
      headCellProps: {
        style: {
          fontWeight: 600,
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
      component: (props) => {
        const data = { ...props, children: props.row.name ?? props.children };
        return (
          <Box maxWidth={window.innerWidth <= 1280 ? 83 : 180}>
            <SimpleText {...data} />
          </Box>
        );
      },
    },
    {
      label: 'DATA',
      accessor: 'date',
      active: true,
      bodyCellProps: {
        style: {
          whiteSpace: 'nowrap',
          maxWidth: '162px',
        },
      },
      headCellProps: {
        style: {
          width: '162px',
          fontWeight: 600,
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
      component: (props) => {
        return (
          <Box maxWidth={162}>
            <SimpleText>
              {moment(props.children as any).format('L')} às{' '}
              {moment(props.children as any).format('LTS')}
            </SimpleText>
          </Box>
        );
      },
    },
    {
      label: 'SIGNATÁRIOS',
      accessor: 'total',
      active: true,
      bodyCellProps: {
        style: {
          whiteSpace: 'nowrap',
          width: '95px',
        },
      },
      headCellProps: {
        style: {
          width: '130px',
          fontWeight: 600,
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
      component: (props) => (
        <Box maxWidth={95}>
          <SimpleText {...props} />
        </Box>
      ),
    },
    {
      label: 'CPF/CNPJ',
      accessor: 'documents',
      active: true,
      bodyCellProps: {
        style: {
          whiteSpace: 'nowrap',
        },
      },
      headCellProps: {
        style: {
          fontWeight: 600,
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
      component: (props) => {
        const firtsDocument = props?.row.documents.split('•');
        const actualWidth = firtsDocument[0].trim().length === 14 ? 105 : 129;
        return (
          <Box maxWidth={actualWidth}>
            <SimpleText {...props} />
          </Box>
        );
      },
    },
    {
      label: 'STATUS',
      accessor: 'statusLabel',
      active: true,
      bodyCellProps: {
        style: {
          whiteSpace: 'nowrap',
          width: '100px',
        },
      },
      headCellProps: {
        style: {
          width: '100px',
          fontWeight: 600,
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
      component: (props) => (
        <Box maxWidth={100}>
          <EnvelopeChip
            size="small"
            actualColor={envelopeColors[props.row.status]}
            label={props.children as string}
          />
        </Box>
      ),
    },
    {
      label: 'DETALHES',
      accessor: 'action',
      bodyCellProps: {
        style: {
          whiteSpace: 'nowrap',
        },
      },
      headCellProps: {
        style: {
          fontWeight: 600,
          textAlign: 'center',
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
      active: true,
      component: (props) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              sendAnalytics(EVENTS.CLICK_OPEN_SIGNATURE_DETAIL);
              props.onClick(props.row);
            }}
          >
            <Tooltip title="Ver detalhes">
              <ArrowForwardIosIcon
                color="primary"
                style={{ pointerEvents: 'auto' }}
              />
            </Tooltip>
          </IconButton>
        </Box>
      ),
    },
  ];
  return columnsGroup;
};
