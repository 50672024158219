import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineItem from '@material-ui/lab/TimelineItem';
import { styled } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { TimelineContent } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

interface TimelineConnectorStylesProps {
  lineColor?: string;
  height?: number;
}

interface TimelineDotStylesProps {
  dotColor?: string;
}

const timelineStyles = (): CreateCSSProperties => {
  return {
    '&.MuiTimelineItem-missingOppositeContent:before': {
      flex: 'none',
      padding: 0,
    },
  };
};

const timelineConnectorStyles = (props: TimelineConnectorStylesProps) => {
  return {
    width: 1,
    flexGrow: props.height ? 0 : 1,
    transition: 'all 0.3s linear',
    height: props.height ?? 'auto',
    backgroundColor: props.lineColor ?? '#DFDFDF',
  };
};

const timelineContentStyles = () => {
  return {
    minWidth: 0,
  };
};

const timelineDotStyles = (props: TimelineDotStylesProps) => {
  return {
    '&.MuiTimelineDot-outlinedGrey': {
      borderColor: props.dotColor ?? '#DFDFDF',
    },
    transition: 'all 0.3s linear',
    margin: '0 auto',
    padding: props.dotColor ? 4 : 7,
    borderWidth: props.dotColor ? 4 : 1,
  };
};

export const EnvelopeTimelineContent = styled(TimelineContent)(
  timelineContentStyles
);

export const EnvelopeTimelineConnector = styled(TimelineConnector)(
  timelineConnectorStyles
);
export const EnvelopeTimelineDot = styled(TimelineDot)(timelineDotStyles);

export const EnvelopeTimelineItem = styled(TimelineItem)(timelineStyles);

export const SignatoriesSubtitle = styled(Typography)((props) => ({
  color: props.theme.palette.grey[400],
}));
