import React, { useRef, useState } from 'react';
import { useContainerDimensions } from '@src/hook/useContainerDimensions';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingButton = ({
  children,
  onClick,
  disabled,
  style,
  ...rest
}: ButtonProps) => {
  const [isLoading, setLoading] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const { width, height } = useContainerDimensions(ref);
  const handleClick = async (e) => {
    if (onClick) {
      try {
        setLoading(true);
        await onClick(e);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };
  return (
    <Button
      style={style}
      disabled={isLoading || disabled}
      onClick={handleClick}
      {...rest}
    >
      <Box
        width={width === 0 ? 1 : width}
        height={height === 0 ? 1 : height}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? (
          <CircularProgress color="inherit" size="1rem" />
        ) : (
          <div ref={ref}>{children}</div>
        )}
      </Box>
    </Button>
  );
};

export default LoadingButton;
