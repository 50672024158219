import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '@components/temp/TablePaginationActions';
import { Pagination } from '@models/pagination';
import Grid from '@material-ui/core/Grid';

interface PaginationCustomProps {
  pagination: Pagination;
  handlePagination: (page: number, rowsPerPage: number) => void;
}

const PaginationCustom: React.FC<PaginationCustomProps> = ({
  pagination,
  handlePagination,
}) => {
  return (
    <Grid item md={12}>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        ActionsComponent={TablePaginationActions as any}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
        count={pagination.count}
        page={pagination.page === 0 ? pagination.page : pagination.page - 1}
        rowsPerPage={pagination.rowsPerPage}
        onChangePage={(event, page) => {
          handlePagination(page + 1, pagination.rowsPerPage);
        }}
        onChangeRowsPerPage={(event) => {
          handlePagination(1, Number(event.target.value));
        }}
        onPageChange={() => {}}
      />
    </Grid>
  );
};

export default PaginationCustom;
