import { StatusEnvelope } from '@src/utils/enum/status-envelope';
import { SignatoryStatusEnum } from '@src/utils/enum/status-signatory';

export const envelopeColors = {
  [StatusEnvelope.Canceled]: '#EB6A6A',
  [StatusEnvelope.Expired]: '#979797',
  [StatusEnvelope.Process]: '#FF955A',
  [StatusEnvelope.Finished]: '#7FD9B9',
};

export const signatoryColors = {
  [SignatoryStatusEnum.Finished]: '#80E0BE',
  [SignatoryStatusEnum.Canceled]: '#EB6A6A',
  [SignatoryStatusEnum.InSubscription]: '#FFA674',
  [SignatoryStatusEnum.Expired]: '#979797',
};
