import React, { useState } from 'react';

import { ReactComponent as AwaitIcon } from '@assets/icons/await.svg';
import BlockIcon from '@material-ui/icons/Block';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { SignatoryStatusEnum } from '@src/utils/enum/status-signatory';
import { EnvelopeSignatory } from '@src/models/stores/signatures/envelope';
import {
  SignatoryCardContainer,
  SignatoryCardTypography,
  signatoryCardTypographyStyle,
} from './style';
import { EnvelopeChip } from '@src/pages/Signature/style';
import { signatoryColors } from '@src/pages/Signature/models/color';
import LoopIcon from '@material-ui/icons/Loop';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { textToClipboard } from '@src/utils/utils';
import { notify } from '@src/utils/notification';
import Skeleton from '@src/components/Skeleton/Wrapper';
import LoadingButton from '@src/components/LoadingButton';
import { EnvelopeFileCardAction } from '@src/pages/Signature/models/card-actions';
import OverflowTip from '@src/components/OverflowTip';
import {
  getShippingMethodLabel,
  getSignatoryContact,
} from '@src/pages/Signature/utils';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const signatoryIcons = {
  [SignatoryStatusEnum.Finished]: CheckCircleOutlineIcon,
  [SignatoryStatusEnum.Canceled]: BlockIcon,
  [SignatoryStatusEnum.InSubscription]: AwaitIcon,
  [SignatoryStatusEnum.Expired]: ErrorOutlineIcon,
};

interface SignatureCardProps {
  id?: string | number;
  signatory: EnvelopeSignatory;
  isLoadingEnvelope?: boolean;
  onResend: (id: string) => Promise<any>;
  onCopyLink: (id: string) => Promise<string | undefined>;
  onClickEdit: (id: string) => void;
  download: EnvelopeFileCardAction;
}

const SignatoryCard = ({
  id,
  signatory,
  isLoadingEnvelope,
  onResend,
  onCopyLink,
  onClickEdit,
  download,
}: SignatureCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [text, setText] = useState('Clique aqui para copiar o link');

  const color = signatoryColors[signatory.status];
  const Icon = signatoryIcons[signatory.status];

  const handleResend = async () => {
    try {
      setIsLoading(true);
      await onResend(signatory.id);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      setIsLoading(true);
      const result = await onCopyLink(signatory.id);
      if (result) {
        textToClipboard(result);
        setText('Copiado!');
        notify('O link foi copiado com sucesso');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    onClickEdit(signatory.id);
  };

  const isAnyLoadingActive = isLoading || isLoadingEnvelope;

  const isNotInSubscription =
    signatory.status !== SignatoryStatusEnum.InSubscription;

  const shippingMethodLabel = getShippingMethodLabel(signatory.shippingMethod);
  const signatoryContact = getSignatoryContact(signatory);

  const cardContent = (
    <Box
      display="flex"
      alignItems="center"
      minWidth={0}
      style={{ visibility: isAnyLoadingActive ? 'hidden' : 'visible' }}
    >
      <Box padding="0 24px" display="flex" justifyContent="center">
        <Icon style={{ fill: color }} width={24} height={24} />
      </Box>
      <Box flex={1} display="flex" flexDirection="column" minWidth={0}>
        <Box>
          <OverflowTip
            customStyle={signatoryCardTypographyStyle}
            variant="body2"
            value={signatory.name}
          />
        </Box>
        <Box mt={1} mb={1}>
          <EnvelopeChip
            size="small"
            label={signatory.statusMessage}
            actualColor={color}
          />
        </Box>
        <SignatoryCardTypography variant="body2">
          Autenticação:
        </SignatoryCardTypography>
        <Typography
          style={{ lineHeight: '10px', color: 'rgba(0, 0, 0, 0.6)' }}
          variant="caption"
        >
          {shippingMethodLabel}
          {signatoryContact}
        </Typography>
      </Box>
    </Box>
  );

  const skeletonContent = (
    <Box display="flex" alignItems="center" data-testid="skeletonContent">
      <Box padding="0 24px" display="flex" justifyContent="center">
        <Skeleton variant="circle" width={24} height={24} />
      </Box>
      <Box flex={1} display="flex" flexDirection="column">
        <Skeleton variant="text" width={159} />
        <Skeleton mt={1} mb={1} variant="text" width={120} height={20} />
        <Skeleton mb={1.5} variant="text" width={125} />
        <Skeleton variant="text" width={90} />
      </Box>
    </Box>
  );
  return (
    <SignatoryCardContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={1}
        pt={1}
        pb={1}
      >
        {isAnyLoadingActive && skeletonContent}
        {cardContent}
        {!isAnyLoadingActive && (
          <Box display="flex" alignItems="center" mr={4} style={{ gap: '8px' }}>
            {signatory.isPriority && (
              <Typography variant="body2">{signatory.priority}º</Typography>
            )}

            {signatory.status === SignatoryStatusEnum.Finished ? (
              <LoadingButton
                id={`download-document-signatory-${id}`}
                variant="outlined"
                disabled={download.disable}
                onClick={() => download.onClick()}
                style={{ marginLeft: 8 }}
                color="primary"
              >
                Baixar
              </LoadingButton>
            ) : (
              <>
                <IconButton
                  id={`resend-signatory-${id}`}
                  onClick={handleResend}
                  disabled={!signatory.thereIsResend || isLoading}
                  color="primary"
                >
                  <Tooltip title="Reenviar signatário">
                    <LoopIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  id={`copy-signatory-${id}`}
                  onClick={handleCopy}
                  disabled={!signatory.thereIsCopy || isLoading}
                  color="primary"
                >
                  <Tooltip title={text}>
                    <FileCopyIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  id={`edit-signatory-${id}`}
                  data-testid={`edit-signatory-${id}`}
                  onClick={handleEdit}
                  disabled={isNotInSubscription || isLoading}
                  color="primary"
                >
                  <Tooltip title="Editar">
                    <EditOutlinedIcon />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </Box>
        )}
      </Box>
    </SignatoryCardContainer>
  );
};

export default SignatoryCard;
