import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { IBasicStyle } from '@models/basic-style';

const dialogStyles = (props: IBasicStyle): CreateCSSProperties => {
  return {
    backgroundColor: props.theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '75px',
  };
};

const buttonStyles = (props: IBasicStyle): CreateCSSProperties => {
  return {
    color: props.theme.palette.background.default,
    border: `1px solid ${props.theme.palette.common.white}`,
    borderRadius: '5px',
    textTransform: 'none',
  };
};

const regionCountPageStyles = (props: IBasicStyle): CreateCSSProperties => {
  return {
    position: 'fixed',
    bottom: '30px',
    backgroundColor: props.theme.palette.primary.main,
    color: props.theme.palette.common.white,
    padding: '10px 24px',
    borderRadius: '4px',
  };
};

export const ButtonHeaderPdf = styled(Button)(buttonStyles);
export const RegionCountPagePdf = styled('div')(regionCountPageStyles);
export const DialogHeaderPdf = styled('div')(dialogStyles);
