import { CLICK } from '@constants/analytics-action';

const SIDEBAR = '[SIDEBAR]';

export const EVENTS = {
  CLICK_SIGNATURE: {
    eventCategory: `${SIDEBAR}[ASSINATURAS]`,
    eventAction: CLICK,
  },
};
