import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { IBasicStyle } from '@models/basic-style';
import { styled } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

interface EnvelopeChipStyleProps extends IBasicStyle, ChipProps {
  actualColor: string;
}

interface DashcardProgressProps extends IBasicStyle {
  progressColor?: string;
}

const progressStyle = (props: DashcardProgressProps) => ({
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: props.progressColor ?? props.theme.palette.primary.main,
    transition: 'all  0.4s ease-in-out',
  },
});

export const EnvelopeLinearProgress = styled(LinearProgress)(progressStyle);

export const EnvelopeTypography = styled(Typography)(() => {
  return {
    color: '#666666',
    fontWeight: 600,
  };
});

export const EnvelopeChip = styled(({ actualColor, ...props }) => (
  <Chip {...props} />
))((props: EnvelopeChipStyleProps) => ({
  height: 20,
  fontSize: '0.75rem',
  backgroundColor: props.disabled
    ? props.theme.palette.grey[400]
    : props.actualColor,
  color: props.disabled ? 'black' : props.theme.palette.common.white,
}));
