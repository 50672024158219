const regex = {
  email: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  hex: /^#[0-9A-F]{6}$/i,
  guid: /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
};

export const isValidEmail = (email) => {
  return regex.email.test(email);
};

export const isValidHex = (hex) => {
  return regex.hex.test(hex);
};

export const isLengthGte = (string, valueLength) => {
  return string.length >= valueLength;
};

export const isValidGuid = (value) => {
  return regex.guid.test(value);
};
