import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

const subtitleStyles = (): CreateCSSProperties => {
  return {
    marginTop: '8px',
    color: 'rgba(0, 0, 0, 0.6)',
  };
};

export const SubtitleSignatureHeader = styled(Typography)(subtitleStyles);
