import React, { CSSProperties } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { SkeletonCustom } from '@components/Skeleton';

interface WrapperSkeletonProps extends BoxProps {
  quantity?: number;
  styleContainer?: CSSProperties;
  colorMain?: string;
  colorMiddle?: string;
  variant?: 'circle' | 'rect' | 'text' | undefined;
}

const WrapperSkeleton: React.FC<WrapperSkeletonProps> = ({
  quantity,
  styleContainer,
  colorMain,
  colorMiddle,
  variant,
  ...rest
}) => {
  const colorMainSkeleton = colorMain ?? '#EAEAEA';
  const colorMiddleSkeleton = colorMiddle ?? '#EAEAEA';
  return (
    <Box display="flex" flexWrap="wrap" style={styleContainer}>
      {Array.from({ length: Number(quantity ?? 1) }, (_, i) => {
        return (
          <Box {...rest} key={i}>
            <SkeletonCustom
              variant={variant ?? 'rect'}
              width={rest.width}
              height={rest.height}
              colorMain={colorMainSkeleton}
              colorMiddle={colorMiddleSkeleton}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default WrapperSkeleton;
