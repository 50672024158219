import { combineReducers } from '@reduxjs/toolkit';
import uploadReducer from '../upload';
import signatureWizardReducer from '../signatures/signature-wizard';
import layoutReducer from '../layout';

const rootReducer = combineReducers({
  upload: uploadReducer,
  layout: layoutReducer,
  signatureWizard: signatureWizardReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
