import { styled } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableContainer, {
  TableContainerProps,
} from '@material-ui/core/TableContainer';
import { IBasicStyle } from '@src/models/basic-style';

interface TableContainerCustomProps extends TableContainerProps {
  component: any;
  width: string;
}

const styles = (props: TableContainerCustomProps) => {
  return {
    width: props.width,
    overflowWwap: 'anywhere',
  };
};

const stylesContent = (props: IBasicStyle): CreateCSSProperties => {
  return {
    '&.MuiTable-root': {
      overflowWrap: 'anywhere',
    },
  };
};

export const TableContentCustom = styled(Table)(stylesContent);
export const TableContainerCustom = styled(TableContainer)(styles);
