import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AUPreventMissClick } from '@assertiva/assertiva-ui';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Type } from '@src/models/dynamic-field';
import { captureTypes } from '@src/utils/DynamicFields/fields';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import EnvelopeFilterModel from '../../models/envelope-filter';
import { FILTER_LABEL } from '../../constants/label';
import CustomDrawer from '@src/components/Drawer';
import { DrawerContent } from '@src/components/Drawer/styles';
import AutocompleteUserField from '@components/AutocompleteUserField';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

const localePtBr = moment.locale('pt-br');

const initialFormValues = {
  document: '',
  endDate: undefined,
  envelope: '',
  startDate: undefined,
};

interface EnvelopeFilterProps {
  open?: boolean;
  defaultValues?: EnvelopeFilterModel;
  onClose: () => void;
  onFilter: (filter: EnvelopeFilterModel) => void;
}

const EnvelopeFilter = ({
  onClose,
  onFilter,
  open,
  defaultValues,
}: EnvelopeFilterProps) => {
  const Field = captureTypes['string'];
  const { control, handleSubmit, errors, watch, trigger } =
    useForm<EnvelopeFilterModel>({
      defaultValues: defaultValues,
      mode: 'onChange',
      reValidateMode: 'onChange',
    });
  const formValues = watch();

  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(
    onClose,
    () =>
      _isEmpty(formValues) ||
      _isEqual(initialFormValues, formValues) ||
      _isEqual(defaultValues, formValues)
  );

  return (
    <CustomDrawer
      open={open}
      width={350}
      elevation={1}
      title="Filtros"
      close={preventMissClick}
    >
      <DrawerContent>
        <Box p={2}>
          <Paper elevation={1}>
            <Box p={3} pt={4}>
              <Typography variant="caption">
                Utilize as opções abaixo para exibir apenas os envelopes
                desejados.
              </Typography>
              <Box mt={4}>
                <Typography variant="caption">Opções</Typography>
                <Box
                  mt={2}
                  style={{ gap: '16px' }}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Field
                    id="envelope-filter-id"
                    name="envelope"
                    value={defaultValues?.envelope}
                    fullWidth
                    control={control}
                    errors={errors}
                    type={Type.String}
                    label={FILTER_LABEL.envelope}
                  />
                  <Field
                    id="envelope-filter-document"
                    name="document"
                    fullWidth
                    value={defaultValues?.document}
                    control={control}
                    errors={errors}
                    type={Type.String}
                    label={FILTER_LABEL.document}
                  />
                  <Field
                    id="envelope-filter-email"
                    name="mail"
                    fullWidth
                    value={defaultValues?.mail}
                    control={control}
                    errors={errors}
                    type={Type.String}
                    label={FILTER_LABEL.email}
                  />
                  <Controller
                    name="user"
                    control={control}
                    defaultValue={defaultValues?.user}
                    render={({ onChange, value, name, ...rest }) => (
                      <AutocompleteUserField
                        resetFieldAfterFetch={false}
                        size="small"
                        groupId={null}
                        value={value}
                        onChange={(userItem) => {
                          if (!userItem) {
                            onChange(undefined);
                          } else {
                            onChange({
                              name: userItem?.name,
                              id: Number(userItem?.id),
                            });
                          }
                        }}
                        {...rest}
                      />
                    )}
                  />
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale={localePtBr}
                  >
                    <Controller
                      name="createStartDate"
                      control={control}
                      defaultValue={defaultValues?.createStartDate}
                      rules={{
                        validate: (value) => {
                          const endDateWatch = watch('createEndDate');

                          if (!!!value && endDateWatch) {
                            return 'Informe uma data inicial';
                          }
                          if (value && !value.isValid()) {
                            return 'Informe uma data válida';
                          }
                          if (
                            value &&
                            endDateWatch &&
                            endDateWatch.isValid() &&
                            endDateWatch < value
                          ) {
                            return 'A data inicial não pode ser maior do que a final';
                          }
                          return true;
                        },
                      }}
                      render={({ onChange, value, name, ...rest }) => (
                        <KeyboardDatePicker
                          {...rest}
                          fullWidth
                          id="envelope-filter-start-date"
                          name="createStartDate"
                          cancelLabel="Cancelar"
                          size="small"
                          label={FILTER_LABEL.createStartDate}
                          inputVariant="outlined"
                          InputProps={{
                            style: {
                              padding: 0,
                            },
                          }}
                          keyboardIcon={<EventIcon color="primary" />}
                          value={value ?? null}
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            onChange(e);
                            trigger('createEndDate');
                          }}
                          error={errors.createStartDate !== undefined}
                          helperText={errors.createStartDate?.message}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="createEndDate"
                      control={control}
                      defaultValue={defaultValues?.createEndDate}
                      rules={{
                        validate: (value) => {
                          const startDateWatch = watch('createStartDate');
                          if (!!!value && startDateWatch) {
                            return 'Informe uma data final';
                          }
                          if (value && !value.isValid()) {
                            return 'Informe uma data válida';
                          }
                          if (
                            value &&
                            startDateWatch &&
                            startDateWatch > value
                          ) {
                            return 'A data inicial não pode ser maior do que a final';
                          }
                          return true;
                        },
                      }}
                      render={({ onChange, value, name, ...rest }) => (
                        <KeyboardDatePicker
                          fullWidth
                          id="envelope-filter-end-date"
                          name="createEndDate"
                          cancelLabel="Cancelar"
                          size="small"
                          label={FILTER_LABEL.createEndDate}
                          InputProps={{
                            style: {
                              padding: 0,
                            },
                          }}
                          inputVariant="outlined"
                          value={value ?? null}
                          keyboardIcon={<EventIcon color="primary" />}
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            onChange(e);
                            trigger('createStartDate');
                          }}
                          error={errors.createEndDate !== undefined}
                          helperText={errors.createEndDate?.message}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          {...rest}
                        />
                      )}
                    />
                    <Controller
                      name="signatureStartDate"
                      control={control}
                      defaultValue={defaultValues?.signatureStartDate}
                      rules={{
                        validate: (value) => {
                          const endDateWatch = watch('signatureEndDate');

                          if (!!!value && endDateWatch) {
                            return 'Informe uma data inicial';
                          }
                          if (value && !value.isValid()) {
                            return 'Informe uma data válida';
                          }
                          if (
                            value &&
                            endDateWatch &&
                            endDateWatch.isValid() &&
                            endDateWatch < value
                          ) {
                            return 'A data inicial não pode ser maior do que a final';
                          }
                          return true;
                        },
                      }}
                      render={({ onChange, value, name, ...rest }) => (
                        <KeyboardDatePicker
                          {...rest}
                          fullWidth
                          id="envelope-filter-start-date"
                          name="signatureStartDate"
                          cancelLabel="Cancelar"
                          size="small"
                          label={FILTER_LABEL.signatureStartDate}
                          inputVariant="outlined"
                          InputProps={{
                            style: {
                              padding: 0,
                            },
                          }}
                          keyboardIcon={<EventIcon color="primary" />}
                          value={value ?? null}
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            onChange(e);
                            trigger('signatureEndDate');
                          }}
                          error={errors.signatureStartDate !== undefined}
                          helperText={errors.signatureStartDate?.message}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="signatureEndDate"
                      control={control}
                      defaultValue={defaultValues?.signatureEndDate}
                      rules={{
                        validate: (value) => {
                          const startDateWatch = watch('signatureStartDate');
                          if (!!!value && startDateWatch) {
                            return 'Informe uma data final';
                          }
                          if (value && !value.isValid()) {
                            return 'Informe uma data válida';
                          }
                          if (
                            value &&
                            startDateWatch &&
                            startDateWatch > value
                          ) {
                            return 'A data inicial não pode ser maior do que a final';
                          }
                          return true;
                        },
                      }}
                      render={({ onChange, value, name, ...rest }) => (
                        <KeyboardDatePicker
                          fullWidth
                          id="envelope-filter-end-date"
                          name="signatureEndDate"
                          cancelLabel="Cancelar"
                          size="small"
                          label={FILTER_LABEL.signatureEndDate}
                          InputProps={{
                            style: {
                              padding: 0,
                            },
                          }}
                          inputVariant="outlined"
                          value={value ?? null}
                          keyboardIcon={<EventIcon color="primary" />}
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            onChange(e);
                            trigger('signatureStartDate');
                          }}
                          error={errors.signatureEndDate !== undefined}
                          helperText={errors.signatureEndDate?.message}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <Box
                  display="flex"
                  mt={3}
                  justifyContent="end"
                  color="rgba(0, 0, 0, 0.6)"
                  style={{ gap: '16px' }}
                >
                  <Button
                    id="envelope-filter"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onFilter)}
                  >
                    Filtrar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </DrawerContent>
      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
      />
    </CustomDrawer>
  );
};

export default EnvelopeFilter;
