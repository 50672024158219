import Button from '@material-ui/core/Button';
import { IBasicStyle } from './../../../../models/basic-style';
import { styled } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

const containerStyles = (props: IBasicStyle): CreateCSSProperties => {
  return {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    minHeight: 65,
    minWidth: 50,
    borderRadius: '4px',
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    background: props.theme.palette.common.white,
  };
};

export const DashcardContainer = styled(Button)(containerStyles);
