import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';

export const SignatoryCardContainer = styled(Paper)(() => ({
  width: '100%',
  height: '100%',
  boxShadow:
    '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
}));

export const signatoryCardTypographyStyle = {
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 700,
};

export const SignatoryCardTypography = styled(Typography)(
  () => signatoryCardTypographyStyle
);
