import FileSaver from 'file-saver';
import Promise from 'bluebird';
import JsZip from 'jszip';
import { FileDownloadModel } from '@src/models/file-download.model';
import { FileLink } from '@src/models/file-link.model';
import { AULoadingManager } from '@assertiva/assertiva-ui';

export const getExtensionFile = (name: string) => {
  return name.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2)?.toLowerCase();
};

const generateBlobFromUrl = (url: string) => {
  return fetch(url).then((resp) => resp.blob());
};

const downloadInBatch = (
  files: FileLink[],
  files_per_group = 10
): Promise<FileDownloadModel[]> => {
  return Promise.map(
    files,
    async (file: FileLink) => {
      return new FileDownloadModel(
        !file.name.split('.')[1] ? file.name + '.pdf' : file.name,
        await generateBlobFromUrl(file.url)
      );
    },
    { concurrency: files_per_group }
  );
};
const generateZip = (files: FileDownloadModel[], zipName: string) => {
  const zip = JsZip();
  files.forEach((file) => {
    zip.file(file.name.replaceAll(/[^a-zA-Z0-9À-ȕ.]+/g, '_'), file.blob);
  });
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    const currentDate = new Date().getTime();
    const fileName = `${zipName}-${currentDate}.zip`;
    return FileSaver.saveAs(zipFile, fileName);
  });
};

export const downloadZip = async (
  files: FileLink[],
  zipName: string,
  disableGlobalLoading?: boolean
) => {
  if (!disableGlobalLoading) AULoadingManager.show();
  const batch = await downloadInBatch(files);
  generateZip(batch, zipName);
  AULoadingManager.close();
};
