import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';

export const DrawerContainer = styled(Paper)`
  // position: fixed;
  // top: 0;
  // right: 0;
  width: ${(props) => `${props.style.width}px`};
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #f5f3f3;
  border-radius: 0px;
`;

export const DrawerContent = styled.div`
  //margin-top: auto;
  margin-bottom: auto;
  overflow: auto;
  display: flex;
  height: inherit;
`;

export const DrawerAction = styled.div`
  background-color: #ffffff;
  min-height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
`;
