import { createMuiTheme } from '@material-ui/core/styles';

export const styledTheme = {
  font: {
    family: 'Roboto, sans-serif',
  },
  fontSizes: {
    h1: '1.75rem',
    h2: '1.50rem',
    h3: '1.25rem',
    h4: '1.15rem',
    h5: '0.875rem',
    h6: '0.75rem',
    normal: '1rem',
  },
  fontWeight: {
    normal: '400',
    boldLight: 500,
  },
  colors: {
    primary: '#8a00c1',
    secondary: '#008282',
    black: '#000000',
    white: '#ffffff',
    success: '#2c7a7b',
    warning: '',
    error: '#c53030',
    errorLight: '#feb8b8',
    info: '#2b6cb0',
    infoLight: '#ebf8ff',
    gray100: '#f4f5f7',
    gray200: '#e6e6e6',
    gray500: '#b3b2b2',
    gray900: '#3b3a39',
    dimmer: '#000000d9',
  },
};

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#f3e5f5',
      main: '#8a00C1',
      dark: '#5f00b3',
    },
    secondary: {
      light: '#aeebe9',
      main: '#34d0d1',
      dark: '#008282',
      grey: '#00000099',
    },
    error: {
      main: '#b00020',
    },
    light: {
      light: '#00000029',
      main: '#ffffff',
      dark: '#00000099',
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiTypography: {
      root: {
        wordWrap: 'break-word',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 1px 3px #00000033',
      },
    },
    MuiInput: {
      spellCheck: true,
      lang: 'pt',
    },
    MuiTableRow: {
      hover: {
        '&&&:hover': {
          backgroundColor: 'rgba(222, 247, 246, 0.5)',
        },
      },
    },
    MuiChip: {
      root: {
        fontWeight: 400,
        letterSpacing: '.89px',
      },
    },
    MuiFormHelperText: {
      root: {
        fontStyle: 'italic',
        color: '#979797',
      },
    },
  },
  props: {
    MuiSwipeableDrawer: {
      disableEnforceFocus: true,
    },
    MuiDrawer: {
      disableEnforceFocus: true,
    },
  },
  typography: {
    overline: {
      fontSize: '0.625rem',
      fontWeight: 600,
    },
  },
});
