import { sendAnalytics } from '@src/utils/analytics';
import EnvelopeFilterModel from './models/envelope-filter';
import { EVENTS } from './constants/analytics';
import { FILTER_LABEL } from './constants/label';
import { SignatoryFormValue } from '@src/stores/signatures/signature-wizard/interfaces/signatory-form-value';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export const mountAnalyticsData = (filter: EnvelopeFilterModel) => {
  const options = Object.keys(filter).reduce<string[]>((arr, el) => {
    if (el !== 'status' && filter[el]) {
      if (['Date'].includes(el)) {
        return arr.concat(`${FILTER_LABEL[el]} (data)`);
      }
      return arr.concat(FILTER_LABEL[el]);
    }
    return arr;
  }, []);

  options.forEach((option) => {
    sendAnalytics({ ...EVENTS.SELECT_SIGNATURE_FILTER, eventLabel: option });
  });
};

export const getShippingMethodLabel = (shippingMethod: string) => {
  const shippingMethodLabels = {
    sms: 'SMS',
    email: 'E-mail',
  };

  const method = shippingMethodLabels[shippingMethod] || shippingMethod;

  return `${method}:`;
};

export const getSignatoryContact = (signatory: SignatoryFormValue) =>
  signatory.shippingMethod === 'sms' ? signatory.phone : signatory.email;

export const filterChipsLabels = {
  status: 'Status',
  envelope: 'Envelope',
  document: 'Documento',
  mail: 'E-mail',
  user: 'Usuário',
  createStartDate: 'Data de criação',
  signatureStartDate: 'Data de assinatura',
};

export const mountDate = (
  key: string,
  compareKey: string,
  startDate?: MaterialUiPickersDate,
  endDate?: MaterialUiPickersDate
) => {
  if (key === compareKey && startDate && endDate) {
    const convertDate = `${startDate.format('DD/MM/YYYY')} à ${endDate.format(
      'DD/MM/YYYY'
    )}`;
    return { id: key, label: filterChipsLabels[key], value: convertDate };
  }
};
