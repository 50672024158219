import React, { useMemo } from 'react';
import { getProductTrailSuggestion } from '@assertiva/assertiva-ui';
import Welcome from './components/Welcome';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const HomePage = () => {
  const trail = useMemo(getProductTrailSuggestion, []);

  return (
    <Paper>
      <Box
        alignItems="center"
        flexDirection="column"
        display="flex"
        minHeight={`calc(100vh - ${trail ? '212px' : '152px'})`}
        width="100%"
        padding={3}
      >
        <Welcome />
      </Box>
    </Paper>
  );
};

export default HomePage;
