import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './config/rootReducer';

declare let module: any;

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./config/rootReducer', async () => {
    const newRootReducer = await import('./config/rootReducer');
    store.replaceReducer(newRootReducer.default);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export { rootReducer };
export default store;
