import { createAxiosInstance } from '@assertiva/assertiva-ui';
// import axios from 'axios';
import history from '@src/browserHistory';
import Cookies from 'universal-cookie';

const { REACT_APP_BASE_URL } = process.env;

const baseURL = `${REACT_APP_BASE_URL}/`;

const instance = createAxiosInstance({
  onNotAuthenticate: () => {
    localStorage.clear();
    const cookies = new Cookies();
    const accessTokenCookie = document.cookie
      .split(';')
      .find((x) => x.includes('accessToken'));
    if (accessTokenCookie) {
      const eqPos = accessTokenCookie.indexOf('=');
      const name =
        eqPos > -1 ? accessTokenCookie.substr(0, eqPos) : accessTokenCookie;
      cookies.remove(name, {
        path: '/',
        domain: '.assertivasolucoes.com.br',
      });
    }
    history.push('/');
  },
});

instance.defaults.baseURL = baseURL;

instance.interceptors.response.use((response) => {
  if (response.status === 204) {
    return response;
  }
  return response.data;
});

export default instance;
