import _debounce from 'lodash/debounce';
import React from 'react';
import DialogCustom from '@components/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

//TODO: REFAZER ESSA MODAL PARA O BUILDER TBM
interface ModalWarningProps {
  type: 'info' | 'action';
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  submitBtnText?: string;
  cancelBtnText?: string;
  title?: string;
}

const useStyles = makeStyles(() => ({
  paper: {
    width: '509px',
  },
}));

const ModalRequestFormlWarning: React.FC<ModalWarningProps> = ({
  open,
  onClose,
  onConfirm,
  children,
  type,
  submitBtnText = 'Sim',
  cancelBtnText = 'Não',
  title = 'Atenção!',
}) => {
  const classes = useStyles();
  return (
    <DialogCustom open={open} classes={{ paper: classes.paper }}>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">{title}</Typography>
        </Box>
        <Box color="rgba(0, 0, 0, 0.6)">
          <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>
            {children}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box padding="8px 24px 16px">
          {type === 'info' ? (
            <Button color="primary" onClick={onClose} variant="contained">
              OK
            </Button>
          ) : (
            <Box display="flex" gridGap={24}>
              <Button color="primary" onClick={onClose}>
                {cancelBtnText}
              </Button>
              <Button
                color="primary"
                onClick={_debounce(onConfirm, 300)}
                variant="contained"
              >
                {submitBtnText}
              </Button>
            </Box>
          )}
        </Box>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalRequestFormlWarning;
