import React, { useEffect } from 'react';
import instance from '@src/infrastructure/http-client/instanceAuth2';
import { notify } from '@utils/notification';
import axios from 'axios';

const ErrorInterceptor: React.FC = ({ children }) => {
  useEffect(() => {
    const resInterceptor = instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (!axios.isCancel(error)) {
          const { status, data = {} } = error.response || {};
          if (status === 401) {
            notify(
              data.error_description ||
                'Sua sessão expirou ou está inválida. Faça um novo login para continuar.'
            );
          } else if ([500, 504, 400].includes(status)) {
            if (data.messages && data.messages.length > 0) {
              data.messages.forEach((message: string) => {
                notify(message);
              });
            } else
              notify(
                'Falha ao estabelecer comunicação com o sistema, por favor tente novamente ou entre em contato com o nosso suporte.'
              );
          } else if (status === 403)
            notify(
              'Você não possui permissão para executar essa ação, verifique com seu administrador.'
            );
          else notify('Ocorreu um erro inesperado');
          return Promise.reject(error);
        }
      }
    );
    return () => {
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  return <>{children}</>;
};

export default ErrorInterceptor;
