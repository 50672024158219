import React, { useEffect, useMemo, useState } from 'react';

import { unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch } from '@src/stores';
import {
  cancelEnvelope,
  fetchAllDocumentsEnvelope,
  fetchDocumentEnvelope,
  fetchEnvelope,
  fetchSignatoryLink,
  putSignatory,
  resendEnvelope,
  resendSignatory,
} from '@src/stores/signatures/signature-wizard';
import { useDispatch } from 'react-redux';
import {
  Envelope,
  EnvelopeSignatory,
} from '@src/models/stores/signatures/envelope';
import CustomDrawer from '@src/components/Drawer';
import { DrawerContent } from '@src/components/Drawer/styles';

import EnvelopeDetailHeader from './Header';
import { StatusEnvelope } from '@src/utils/enum/status-envelope';
import { SignatoryStatusEnum } from '@src/utils/enum/status-signatory';

import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Timeline from '@material-ui/lab/Timeline';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import LoopIcon from '@material-ui/icons/Loop';
import BlockIcon from '@material-ui/icons/Block';
import {
  EnvelopeTimelineConnector,
  EnvelopeTimelineContent,
  EnvelopeTimelineDot,
  EnvelopeTimelineItem,
  SignatoriesSubtitle,
} from './style';
import EnvelopeFileCard from './EnvelopeFileCard';
import { envelopeColors, signatoryColors } from '../../models/color';
import { EnvelopeTypography } from '../../style';
import SignatoryCard from './SignatoryCard';
import Skeleton from '@src/components/Skeleton/Wrapper';
import { ErrorHandled } from '@src/models/stores/error-handled';
import { notify } from '@src/utils/notification';
import FileModel from '@src/models/components/file';
import { downloadZip } from '@src/utils/file-download-utils';
import { FileLink } from '@src/models/file-link.model';
import PdfView from '@src/components/PdfView';
import { getFile } from '@src/utils/file';
import { exportDocumentHandler } from '@src/utils/utils';
import ModalWarning from '@src/components/ModalWarning';
import { loadingColor, mockDetail } from './constants';
import { sendAnalytics } from '@src/utils/analytics';
import { EVENTS } from '../../constants/analytics';
import { EnvelopeDetailInitial } from '../../models/envelope-detail';
import { SignatoryFormValue } from '@src/stores/signatures/signature-wizard/interfaces/signatory-form-value';
import { AULoadingManager } from '@assertiva/assertiva-ui';
import {
  BUSignatoryContext,
  BUSignatoryForm,
  SignatoryTypes,
} from '@assertiva/business-ui';

const timelineColors = {
  ...signatoryColors,
  [SignatoryStatusEnum.InSubscription]: undefined,
};

const mapSignatoryFormValue = ({
  document,
  email,
  id,
  name,
  phone,
  shippingMethod,
  signatureProfile,
}: EnvelopeSignatory): SignatoryFormValue => ({
  document,
  email,
  id,
  name,
  phone,
  shippingMethod,
  signatureProfile,
});

interface EnvelopeDetailProps {
  envelopeDetailInitial?: EnvelopeDetailInitial;
  onClose: () => void;
}

const EnvelopeDetail = ({
  envelopeDetailInitial,
  onClose,
}: EnvelopeDetailProps) => {
  const [detail, setDetail] = useState<Envelope>();
  const [file, setFile] = useState<FileModel>();
  const [isLoading, setIsLoading] = useState<boolean | undefined>(true);
  const [disableVisualization, setDisableVisualization] = useState<boolean>();
  const [disableDownload, setDisableDownload] = useState<boolean>();
  const [openModalConfirmation, setOpenModalConfirmation] =
    useState<boolean>(false);
  const [signatoryIdToEdit, setSignatoryIdToEdit] = useState<string>();
  const [signatoryIdToConfirmResend, setSignatoryIdToConfirmResend] =
    useState<string>();
  const [addSignatoryOpen, setAddSignatoryOpen] = useState(false);
  const [signatoryToEdit, setSignatoryToEdit] = useState<SignatoryFormValue>();
  const [signatureProfiles, setSignatureProfiles] = useState<
    SignatoryTypes.SignatureProfileCombo[]
  >([]);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (envelopeDetailInitial?.id) {
      setIsLoading(true);
      dispatch(fetchEnvelope(envelopeDetailInitial.id))
        .then(unwrapResult)
        .then((result) => {
          setDetail(result);
          setIsLoading(false);
        })
        .catch(() => {
          onClose();
          setIsLoading(false);
        });
    }
  }, [envelopeDetailInitial, dispatch, onClose]);

  const handleFetchEnvelope = async (id: string) => {
    const result = await dispatch(fetchEnvelope(id));
    if (result.payload && !(result.payload instanceof ErrorHandled)) {
      setDetail(result.payload);
      return true;
    }
  };

  const handleResendSignatory = async (
    idEnvelope: string,
    idSignatory: string
  ) => {
    const result = await dispatch(resendSignatory(idSignatory));
    if (result.payload) {
      await handleFetchEnvelope(idEnvelope);
      notify('O envelope foi reenviado para o signatário com sucesso');
    }
    return result;
  };

  const handleConfirmResendEdit = async () => {
    if (signatoryIdToConfirmResend) {
      try {
        AULoadingManager.show();

        if (detail) {
          const result = await handleResendSignatory(
            detail.id,
            signatoryIdToConfirmResend
          );

          if (result?.payload) {
            setSignatoryIdToConfirmResend(undefined);
          }
        }
      } finally {
        AULoadingManager.close();
      }
    }
  };

  const handleFetchSignatoryLink = async (id: string) => {
    const result = await dispatch(fetchSignatoryLink(id));
    if (result.payload && !(result.payload instanceof ErrorHandled)) {
      return result.payload;
    }
  };

  const handleCancelEnvelope = async (id: string) => {
    try {
      setIsLoading(true);
      const hasSuccess = await dispatch(cancelEnvelope(id));
      if (hasSuccess.payload) {
        await handleFetchEnvelope(id);
        notify('O envelope foi cancelado com sucesso');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleResendEnvelope = async (id: string) => {
    try {
      setIsLoading(true);
      const result = await dispatch(resendEnvelope(id));
      if (result.payload) {
        await handleFetchEnvelope(id);
        notify('O envelope foi reenviado com sucesso');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDownloadAllFilesEnvelope = async (
    envelope: Envelope,
    withAudit?: boolean
  ) => {
    try {
      setDisableDownload(true);
      const resultFetchDocuments = await dispatch(
        fetchAllDocumentsEnvelope({ id: envelope.id, withAudit: !!withAudit })
      );
      if (
        resultFetchDocuments.payload &&
        !(resultFetchDocuments.payload instanceof ErrorHandled)
      ) {
        const documentsPair = resultFetchDocuments.payload;

        const files = documentsPair.map<FileLink>((pair) => {
          return {
            name: `${pair.id}`,
            url: pair.value,
          };
        });

        if (files.length > 1)
          await downloadZip(files, envelope.name ?? 'documentos', true);
        else
          await exportDocumentHandler(files[0].url, files[0].name, 'pdf', true);
        notify('O download foi realizado com sucesso');
        setDisableDownload(false);
      }
    } catch (error) {
      setDisableDownload(false);
    }
  };

  const fetchDocument = async (idEnvelope: string, idDocument: string) => {
    const result = await dispatch(
      fetchDocumentEnvelope({ idDocument, idEnvelope })
    );

    if (result.payload && !(result.payload instanceof ErrorHandled)) {
      return result.payload;
    }
  };

  const handleVisualizationDocument = async (
    idEnvelope: string,
    idDocument: string
  ) => {
    try {
      setDisableVisualization(true);
      const keyValue = await fetchDocument(idEnvelope, idDocument);

      if (keyValue) {
        const fileForm = await getFile(keyValue.value, keyValue.value);

        setFile({
          id: idDocument,
          extension: 'pdf',
          name: keyValue.id,
          isDefault: false,
          url: keyValue.value,
          fileForm,
        });
        setDisableVisualization(false);
      }
    } catch (error) {
      setDisableVisualization(false);
    }
  };

  const handleDownloadDocument = async (
    idEnvelope: string,
    idDocument: string
  ) => {
    try {
      setDisableDownload(true);

      const keyValue = await fetchDocument(idEnvelope, idDocument);

      if (keyValue) {
        await exportDocumentHandler(keyValue.value, keyValue.id, 'pdf', true);
        notify('O download foi realizado com sucesso');
      }
      setDisableDownload(false);
    } catch (error) {
      setDisableDownload(false);
    }
  };

  const handleCloseVisualization = () => setFile(undefined);

  const getLineColor = (actualIndex: number) => {
    if (detail) {
      const { signatories } = detail;
      const targetIndex = actualIndex + 1;
      const lineColor = timelineColors[signatories[targetIndex].status];
      return lineColor;
    }
  };

  const handleConfirmModal = () => {
    sendAnalytics(EVENTS.CLICK_CANCEL_SIGNATURE_DETAIL);
    if (detail) handleCancelEnvelope(detail.id);
    setOpenModalConfirmation(false);
  };

  const handleOpenModalConfirmation = () => {
    setOpenModalConfirmation(true);
  };

  const handleCloseModalConfirmation = () => {
    setOpenModalConfirmation(false);
  };

  const handleCloseDrawer = () => {
    onClose();
    setDetail(undefined);
  };

  const envelopeColor = detail ? envelopeColors[detail.status] : 'white';

  const actualDetail = detail ?? {
    ...mockDetail,
    name: envelopeDetailInitial?.name,
    id: envelopeDetailInitial?.id ?? '',
  };

  const signatoryFormValueToEdit = useMemo(() => {
    if (!signatoryIdToEdit || !detail) return undefined;

    return detail.signatories
      .map(mapSignatoryFormValue)
      .find((signatory) => signatory.id === signatoryIdToEdit);
  }, [detail, signatoryIdToEdit]);

  const handleEditSignatory = async (formValue: SignatoryFormValue) => {
    const id = signatoryFormValueToEdit?.id as string;
    const result = await dispatch(
      putSignatory({
        signatoryFormValue: {
          ...formValue,
          id,
        },
      })
    );

    if (result.payload) {
      try {
        AULoadingManager.show();
        if (detail) await handleFetchEnvelope(detail.id);
        setSignatoryIdToEdit(undefined);
        setSignatoryIdToConfirmResend(id);
      } finally {
        AULoadingManager.close();
      }
    }
  };

  return (
    <CustomDrawer
      title="Detalhes da assinatura"
      elevation={1}
      open={!!envelopeDetailInitial}
      width={window.innerWidth * 0.6}
      close={handleCloseDrawer}
    >
      <DrawerContent>
        <Box
          minWidth={0}
          width={1}
          display="flex"
          pt={3}
          pr={4}
          pl={4}
          pb={2}
          flexDirection="column"
        >
          {actualDetail && (
            <Paper>
              <EnvelopeDetailHeader id={actualDetail.name ?? actualDetail.id}>
                {!isLoading &&
                  (actualDetail.status === StatusEnvelope.Finished ? (
                    <Button
                      id="download-envelope"
                      variant="outlined"
                      disabled={disableDownload}
                      color="primary"
                      onClick={() => {
                        sendAnalytics(
                          EVENTS.CLICK_DOWNLOAD_ENVELOPE_SIGNATURE_DETAIL
                        );
                        handleDownloadAllFilesEnvelope(actualDetail, true);
                      }}
                    >
                      Baixar envelope
                    </Button>
                  ) : (
                    <Box display="flex" style={{ gap: '8px' }}>
                      <IconButton
                        id="resend-envelope"
                        onClick={() => {
                          sendAnalytics(
                            EVENTS.CLICK_SYNC_GERAL_SIGNATURE_DETAIL
                          );
                          handleResendEnvelope(actualDetail.id);
                        }}
                        disabled={!actualDetail.thereIsResend}
                        color="primary"
                      >
                        <Tooltip title="Reenviar envelope">
                          <LoopIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        id="cancel-envelope"
                        onClick={handleOpenModalConfirmation}
                        disabled={!actualDetail.thereIsCancel}
                        color="primary"
                      >
                        <Tooltip title="Cancelar envelope">
                          <BlockIcon />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  ))}
              </EnvelopeDetailHeader>
              <Divider />
              <Box
                pt={3}
                pb={2}
                overflow="auto"
                maxHeight={window.innerHeight - 186}
              >
                <EnvelopeTypography
                  variant="subtitle1"
                  style={{ marginLeft: '48px' }}
                >
                  Documento(s) enviado(s) para assinatura
                </EnvelopeTypography>
                <Timeline align="left">
                  <EnvelopeTimelineItem>
                    <TimelineSeparator>
                      <EnvelopeTimelineConnector
                        height={60}
                        lineColor="transparent"
                      />
                      <EnvelopeTimelineDot
                        variant="outlined"
                        dotColor={isLoading ? loadingColor : envelopeColor}
                      />
                      <EnvelopeTimelineConnector
                        lineColor={isLoading ? loadingColor : envelopeColor}
                      />
                    </TimelineSeparator>
                    <EnvelopeTimelineContent>
                      <Box
                        display="flex"
                        flexDirection="column"
                        style={{ gap: 16 }}
                      >
                        {actualDetail.documents.map((document, index) => {
                          return (
                            <EnvelopeFileCard
                              id={index}
                              key={document.id}
                              isLoadingEnvelope={isLoading}
                              visualization={{
                                disable: disableVisualization,
                                onClick: async (id) => {
                                  if (id) {
                                    sendAnalytics(
                                      EVENTS.CLICK_VIEW_SIGNATURE_DETAIL
                                    );
                                    await handleVisualizationDocument(
                                      actualDetail.id,
                                      id
                                    );
                                  }
                                },
                              }}
                              download={{
                                disable: disableDownload,
                                onClick: async (id) => {
                                  if (id) {
                                    sendAnalytics(
                                      EVENTS.CLICK_DOWNLOAD_FILE_SIGNATURE_DETAIL
                                    );
                                    await handleDownloadDocument(
                                      actualDetail.id,
                                      id
                                    );
                                  }
                                },
                              }}
                              document={document}
                            />
                          );
                        })}
                      </Box>
                      <Box mt={8}>
                        <EnvelopeTypography variant="subtitle1">
                          Lista de signatários
                        </EnvelopeTypography>
                        {!isLoading ? (
                          <SignatoriesSubtitle variant="caption">
                            {actualDetail.totalSigned}/
                            {actualDetail.totalSignature} assinaturas concluídas
                          </SignatoriesSubtitle>
                        ) : (
                          <Skeleton variant="text" width={160} />
                        )}
                      </Box>
                    </EnvelopeTimelineContent>
                  </EnvelopeTimelineItem>
                  {actualDetail.signatories.map((signatory, index) => {
                    const actualTimelineColor =
                      timelineColors[signatory.status];

                    let firstLineColor =
                      index === 0 ? envelopeColor : actualTimelineColor;

                    let lastLineColor =
                      actualDetail.signatories.length - 1 === index
                        ? 'transparent'
                        : getLineColor(index);

                    let dotColor = actualTimelineColor;

                    if (isLoading) {
                      firstLineColor = loadingColor;
                      dotColor = dotColor ? loadingColor : undefined;
                      lastLineColor =
                        lastLineColor === 'transparent'
                          ? lastLineColor
                          : loadingColor;
                    }

                    return (
                      <EnvelopeTimelineItem key={signatory.id}>
                        <TimelineSeparator>
                          <EnvelopeTimelineConnector
                            lineColor={firstLineColor}
                          />
                          <EnvelopeTimelineDot
                            variant="outlined"
                            dotColor={dotColor}
                          />
                          <EnvelopeTimelineConnector
                            lineColor={lastLineColor}
                          />
                        </TimelineSeparator>
                        <EnvelopeTimelineContent>
                          <SignatoryCard
                            id={signatory.id}
                            isLoadingEnvelope={isLoading}
                            signatory={signatory}
                            onCopyLink={(id) => handleFetchSignatoryLink(id)}
                            onResend={(id) => {
                              sendAnalytics(
                                EVENTS.CLICK_SYNC_UNITARY_SIGNATURE_DETAIL
                              );
                              return handleResendSignatory(actualDetail.id, id);
                            }}
                            onClickEdit={(id) => setSignatoryIdToEdit(id)}
                            download={{
                              onClick: () => {
                                sendAnalytics(
                                  EVENTS.CLICK_DOWNLOAD_FILE_SIGNATURE_DETAIL
                                );
                                handleDownloadAllFilesEnvelope(actualDetail);
                              },

                              disable: disableDownload,
                            }}
                          />
                        </EnvelopeTimelineContent>
                      </EnvelopeTimelineItem>
                    );
                  })}
                </Timeline>
              </Box>
            </Paper>
          )}
          {file && (
            <PdfView
              file={file}
              open={!!file}
              onClose={handleCloseVisualization}
            />
          )}
        </Box>
        <ModalWarning
          type="action"
          open={openModalConfirmation}
          onConfirm={handleConfirmModal}
          onClose={handleCloseModalConfirmation}
        >
          Deseja realmente cancelar o envelope? {'\n'}Essa ação não poderá ser
          desfeita!
        </ModalWarning>

        <ModalWarning
          type="action"
          open={Boolean(signatoryIdToConfirmResend)}
          onConfirm={handleConfirmResendEdit}
          onClose={() => setSignatoryIdToConfirmResend(undefined)}
          title="Deseja reenviar para este signatário?"
          submitBtnText="REENVIAR"
          cancelBtnText="CANCELAR"
        >
          Ao confirmar o reenvio, o envelope será enviado novamente somente para
          este signatário.
        </ModalWarning>

        <BUSignatoryContext.Provider
          value={{
            addSignatoryOpen,
            setAddSignatoryOpen,
            signatoryToEdit,
            setSignatoryToEdit,
            signatureProfiles,
            setSignatureProfiles,
          }}
        >
          <BUSignatoryForm
            open={Boolean(signatoryIdToEdit)}
            onClose={() => setSignatoryIdToEdit(undefined)}
            onSubmit={handleEditSignatory}
            drawerTitle="Editar signatário"
            submitBtnText="SALVAR EDIÇÃO"
            signatoryFormValue={signatoryFormValueToEdit}
          />
        </BUSignatoryContext.Provider>
      </DrawerContent>
    </CustomDrawer>
  );
};

export default EnvelopeDetail;
