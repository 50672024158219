import { Envelope } from '@src/models/stores/signatures/envelope';
import { StatusEnvelope } from '@src/utils/enum/status-envelope';
import { SignatoryStatusEnum } from '@src/utils/enum/status-signatory';

export const mockDetail: Envelope = {
  id: '',
  date: new Date(),
  status: StatusEnvelope.Process,
  statusMessage: 'Em processo',
  thereIsCancel: false,
  thereIsResend: false,
  totalSignature: 1,
  totalSigned: 0,
  documents: [
    {
      status: StatusEnvelope.Process,
      statusMessage: 'Em processo',
      documentType: 'original',
      id: '9f79bf57-6795-4515-8703-a6a96f52c1a9',
      date: new Date(),
      name: 'Proposta.pdf',
    },
  ],
  signatories: [
    {
      id: '',
      isPriority: false,
      name: '',
      shippingMethod: 'sms',
      status: SignatoryStatusEnum.InSubscription,
      statusMessage: 'Assinar',
      thereIsCopy: true,
      thereIsResend: true,
      document: '',
      phone: '',
      email: '',
      signatureProfile: 1,
      priority: 1,
    },
  ],
};

export const loadingColor = '#EAEAEA';
