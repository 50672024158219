import React from 'react';
import { EnvelopeDocument } from '@src/models/stores/signatures/envelope';
import { envelopeColors } from '@src/pages/Signature/models/color';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import moment from 'moment';
import {
  EnvelopeChip,
  EnvelopeLinearProgress,
} from '@src/pages/Signature/style';
import { StatusEnvelope } from '@src/utils/enum/status-envelope';
import LoadingButton from '@src/components/LoadingButton';

import Skeleton from '@src/components/Skeleton/Wrapper';
import { EnvelopeFileCardAction } from '@src/pages/Signature/models/card-actions';
import OverflowTip from '@src/components/OverflowTip';
import { sendAnalytics } from '@src/utils/analytics';
import { EVENTS } from '@src/pages/Signature/constants/analytics';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

interface EnvelopeFileCardProps {
  id?: string | number;
  document: EnvelopeDocument;
  isLoadingEnvelope?: boolean;
  download: EnvelopeFileCardAction;
  visualization: EnvelopeFileCardAction;
}

const EnvelopeFileCard = ({
  id,
  document,
  isLoadingEnvelope,
  download,
  visualization,
}: EnvelopeFileCardProps) => {
  const cardContent = (
    <Box
      display="flex"
      flexDirection="column"
      minWidth={0}
      style={{ visibility: isLoadingEnvelope ? 'hidden' : 'visible' }}
    >
      <Box display="flex" style={{ gap: 8 }} mb={2}>
        <PdfIcon style={{ minWidth: 19, minHeight: 19 }} />
        <OverflowTip variant="caption" value={document.name} />
      </Box>
      <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
        Criado em {moment(document.date).format('L')} às{' '}
        {moment(document.date).format('H')}h{moment(document.date).format('mm')}
      </Typography>
      <Box mt={1}>
        <EnvelopeChip
          size="small"
          actualColor={envelopeColors[document.status]}
          label={document.statusMessage}
        />
      </Box>
    </Box>
  );

  return (
    <Paper elevation={3}>
      <Box display={'flex'} width={1} height={1} flexDirection="column">
        <EnvelopeLinearProgress
          variant="determinate"
          progressColor={
            isLoadingEnvelope ? '#EAEAEA' : envelopeColors[document.status]
          }
          value={100}
        />
        <Box
          flex={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mr={3}
          ml={3}
          mt={2}
          mb={1}
        >
          {isLoadingEnvelope && (
            <Box display="flex" flexDirection="column">
              <Skeleton variant="text" width="285px" />
              <Skeleton mt={1.5} variant="text" width="195px" />
              <Skeleton mt={3} mb={1} variant="text" width="125px" />
            </Box>
          )}
          {cardContent}
          {!isLoadingEnvelope && (
            <Box display="flex" flex={1} justifyContent="end">
              {[StatusEnvelope.Process, StatusEnvelope.Finished].includes(
                document.status
              ) && (
                <LoadingButton
                  id={`visualize-document-${id}`}
                  disabled={visualization.disable}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    sendAnalytics(EVENTS.CLICK_VIEW_SIGNATURE_DETAIL);
                    visualization.onClick(document.id);
                  }}
                >
                  Visualizar
                </LoadingButton>
              )}
              {document.status === StatusEnvelope.Finished && (
                <LoadingButton
                  id={`download-document-${id}`}
                  variant="outlined"
                  disabled={download.disable}
                  onClick={() => {
                    sendAnalytics(EVENTS.CLICK_DOWNLOAD_FILE_SIGNATURE_DETAIL);
                    download.onClick(document.id);
                  }}
                  color="primary"
                  style={{ marginLeft: 16 }}
                >
                  Baixar
                </LoadingButton>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default EnvelopeFileCard;
