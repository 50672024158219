import React, { useState } from 'react';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EnvelopeIconButton } from './style';
import { EnvelopeTypography } from '@src/pages/Signature/style';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

interface EnvelopeDetailHeaderProps {
  id: string;
}

const EnvelopeDetailHeader: React.FC<EnvelopeDetailHeaderProps> = ({
  id,
  children,
}) => {
  const [text, setText] = useState('Clique aqui para copiar o id do envelope');
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mr={2}
      mt={2}
      mb={2}
      ml={2}
    >
      <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
        <EnvelopeTypography variant="subtitle1">
          Envelope: {id}
        </EnvelopeTypography>
        <CopyToClipboard text={id} onCopy={() => setText('Copiado!')}>
          <Tooltip title={text}>
            <EnvelopeIconButton aria-label="Copiar id">
              <FileCopyOutlinedIcon
                width="14px"
                height="14px"
                style={{ width: 14, height: 14, fill: '#666666' }}
              />
            </EnvelopeIconButton>
          </Tooltip>
        </CopyToClipboard>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default EnvelopeDetailHeader;
