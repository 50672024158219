import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as LogoSignatories } from '@assets/logo-signatories.svg';
import { StyledPresentationText } from './Welcome.styles';

const Welcome = () => {
  return (
    <Grow in timeout={450}>
      <Box padding={2} width="100%" boxShadow={3}>
        <Box display="flex" justifyContent="center" width="100%" mt={5}>
          <LogoSignatories
            style={{
              width: 74,
              height: 86,
            }}
          />
        </Box>
        <Box mt={2} mb={3}>
          <Typography align="center" variant="h5">
            Bem-vindo(a) ao Assertiva Assinaturas!
          </Typography>
        </Box>
        <Box maxWidth="860px" margin="0 auto" px={2}>
          <StyledPresentationText align="center" data-testid="welcome-text">
            Faça Assinaturas Eletrônicas juridicamente válidas, com mais
            segurança e agilidade.
          </StyledPresentationText>
        </Box>
        <Box display="flex" justifyContent="center" width="100%" mt={4} mb={5}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={'/assinaturas'}
            data-testid="see-debts-btn"
            startIcon={<AddIcon />}
          >
            NOVA ASSINATURA
          </Button>
        </Box>
      </Box>
    </Grow>
  );
};

export default Welcome;
