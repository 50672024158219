import React from 'react';
import { DynamicField, Type } from '@models/dynamic-field';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import { ValidationUtils } from '@assertiva/assertiva-ui';
import dataMap from '@utils/DynamicFields/mask';
import { isValidHex } from '../validation';
import { formatDefaultString } from '../string-utils';
import Box from '@material-ui/core/Box';

export interface FieldMontage extends DynamicField {
  control: Control<Record<string, any>>;
  groupControl?: string;
  errors: DeepMap<Record<string, any>, FieldError>;
  fullWidth?: boolean;
  rows?: number;
  textfieldInputProps?: Pick<
    TextFieldProps,
    'InputProps' | 'InputLabelProps' | 'inputProps'
  >;
}

export const validationType = {
  cpf: (field: string) => ValidationUtils.isValidCpf(field),
  document: (field: string) =>
    ValidationUtils.isValidCpf(field) || ValidationUtils.isValidCnpj(field),
  cnpj: (field: string) => ValidationUtils.isValidCnpj(field),
  celular: (field: string) => ValidationUtils.isValidPhone(field, 'mobile'),
  phone: (field: string) => ValidationUtils.isValidPhone(field, 'mobile'),
  email: (field: string) =>
    field ? ValidationUtils.isValidEmail(field) : true,
  hex: (field: string) => (field ? isValidHex(field) : true),
};

export const messages = {
  maxLength: (value: number, limit: number) => {
    const result = limit - value;
    if (result > 0) {
      return `${result}  caracteres restantes`;
    }
    return 'Nenhum caracter restante';
  },
};

export const errorMessages = {
  required: (field: string) => `O campo "${field}" é obrigatório`,
  maxLength: (field: string, count: string | number | undefined) =>
    `O campo "${field}" não pode ultrapassar ${count ?? 500} caracteres`,
  minLength: (field: string, count: string | number | undefined) =>
    `O campo "${field}" tem que ter no mínimo ${count ?? 500} caracteres`,
  pattern: (field: string, type: string, label: string) =>
    validationType[type]
      ? validationType[type](field) || `O campo "${label}" é inválido`
      : true,
};

//TODO: COLOCAR DEPOIS O VALOR DO CAMPO NO CONTROLLER
export const captureTypes = {
  [Type.String]: (props: FieldMontage) => {
    const {
      control,
      description,
      label,
      value,
      groupControl,
      name,
      required,
      maxLength,
      minLength,
      errors,
      fullWidth,
      rows,
      id,
      customOnChange,
      textfieldInputProps,
    } = props;
    const actualName = groupControl ? `${groupControl}.${name}` : name;
    const actualErrors =
      groupControl && errors[groupControl] ? errors[groupControl] : errors;

    return (
      <Controller
        name={actualName}
        control={control}
        rules={{
          required: {
            value: !!required,
            message: errorMessages.required(label),
          },
          minLength: {
            value: minLength ?? 0,
            message: errorMessages.minLength(label, minLength),
          },
          maxLength: {
            value: maxLength ?? 500,
            message: errorMessages.maxLength(label, maxLength),
          },
          validate: (value) =>
            required && !value.trim()
              ? `O campo "${label}" é inválido`
              : value
              ? errorMessages.pattern(value, name, label)
              : true,
        }}
        defaultValue={value ?? ''}
        render={({ onChange, value }) => (
          <TextField
            id={id}
            fullWidth={fullWidth}
            required={required}
            label={label}
            InputLabelProps={textfieldInputProps?.InputLabelProps}
            InputProps={textfieldInputProps?.InputProps}
            size="small"
            multiline={Boolean(rows)}
            inputProps={{
              ...(textfieldInputProps?.inputProps
                ? {
                    ...textfieldInputProps?.inputProps,
                    maxLength: maxLength ?? 500,
                  }
                : { maxLength: maxLength ?? 500 }),
              'data-testid': id,
            }}
            rows={rows}
            value={value}
            onChange={(e) => {
              const result = dataMap[name];
              e.target.value = result
                ? result({ value: e.target.value })
                : formatDefaultString(e.target.value);

              if (customOnChange) customOnChange(e.target.value);
              onChange(e);
            }}
            error={
              actualErrors[name] &&
              actualErrors[name].message !== '' &&
              actualErrors[name].message !== undefined
            }
            helperText={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  {(actualErrors[name] && actualErrors[name].message) ||
                    description}
                </Box>
                <Box>
                  {maxLength &&
                    messages.maxLength(value.length, Number(maxLength) ?? 500)}
                </Box>
              </Box>
            }
            variant="outlined"
          />
        )}
      />
    );
  },
  [Type.Number]: (props: FieldMontage) => {
    const {
      control,
      description,
      label,
      name,
      required,
      maxLength,
      errors,
      fullWidth,
      id,
    } = props;
    return (
      <Controller
        name={name}
        required={required}
        control={control}
        rules={{
          required: {
            value: !!required,
            message: errorMessages.required(label),
          },
          maxLength: {
            value: maxLength ?? 500,
            message: errorMessages.maxLength(label, maxLength),
          },
          validate: (value) => errorMessages.pattern(value, name, label),
        }}
        defaultValue=""
        render={({ onChange, value }) => (
          <TextField
            id={id}
            fullWidth={fullWidth}
            label={label}
            size="small"
            value={value}
            onChange={(e) => {
              const result = dataMap[name];
              e.target.value = result
                ? result({ value: e.target.value })
                : e.target.value;
              onChange(e);
            }}
            error={
              errors[name] &&
              errors[name].message !== '' &&
              errors[name].message !== undefined
            }
            helperText={(errors[name] && errors[name].message) || description}
            variant="outlined"
          />
        )}
      />
    );
  },
};
