import { getExtensionFile } from './file-download-utils';
import { notify } from './notification';
import FileState from '@models/components/file';
import { ITypeValidation } from '@src/models/file-validation';
const getFiles = (fileList: FileList | File[]) =>
  fileList instanceof FileList ? Array.from(fileList) : fileList;

export const getBase64 = (fileItem: File) => {
  return new Promise<string>((resolve, reject) => {
    const file = fileItem;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      if (reader && reader.result) {
        const base64 = `${file.type};base64,${Buffer.from(
          reader.result as any
        ).toString('base64')}`;
        resolve(base64);
      }
      reject('Não foi possivel converter em base64');
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getBuffer = (fileItem: File) => {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const file = fileItem;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      if (reader && reader.result) {
        resolve(reader.result);
      }
      reject('Não foi possivel converter em buffer');
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getFile = async (url: string, name: string) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  const file = new File([buf], name, { type: 'application/pdf' });
  return file;
};

export const getFileSize = (fileList: FileList | File[] | FileState[]) => {
  const files = fileList instanceof FileList ? getFiles(fileList) : fileList;

  return (files as any)
    .filter((file) => file.size)
    .reduce((number, el) => {
      const totalSize = Number(el.size / 1000000);
      number += totalSize;
      return number;
    }, 0);
};

export const thereFileWithMaxSize = (
  fileList: FileList | File[],
  maxSize: number
) => {
  const files = getFiles(fileList);

  return files.some(
    (file) => Number((file.size / 1000000).toFixed(1)) >= maxSize
  );
};

const isPDF = (buffer: Buffer) => {
  return (
    Buffer.isBuffer(buffer) &&
    buffer.lastIndexOf('%PDF-') === 0 &&
    buffer.lastIndexOf('%%EOF') > -1
  );
};

const isEncrypted = (buffer: Buffer) => {
  return buffer.includes('Encrypt');
};

const verifyIntegrity = async (files: any[]) => {
  const allPdf = Array.from(files).filter(
    (e: any) => getExtensionFile(e.name) === 'pdf'
  );
  if (allPdf.length > 0) {
    const allValidateFiles = await Promise.all(
      allPdf.map(async (file) => {
        const arrayBuffer = await getBuffer(file);
        if (arrayBuffer) {
          const buffer = Buffer.from(arrayBuffer as any);
          const result = isPDF(buffer) && !isEncrypted(buffer);
          return { isValid: result, name: file.path };
        }
        return { isValid: false, name: file.path };
      })
    );
    const allInvalid = allValidateFiles.filter((file) => !file.isValid);
    if (allInvalid.length > 0) {
      notify(`Existe(m) arquivo(s) inválidos e/ou corrompidos`);
      return;
    }
    return true;
  }
};

export const FileValidator = () => {
  let allFiles: any[];
  let fileBase: any[];
  let dropFile: any[];
  let repeatedNameAccepted = true;
  let maxTotalFile: number | undefined;
  let maxDropedFile = 10;
  let maxSize: ITypeValidation = { value: 500, label: '500' };
  let maxName = 0;
  let acceptType: string[] = ['pdf'];
  return Object.assign(
    {},
    {
      forBaseFile: function (fileList) {
        fileBase = fileList;
        return this;
      },

      forDropFile: function (fileList) {
        dropFile = fileList;
        return this;
      },

      withMaxSize: function (maxQuantity: ITypeValidation | number) {
        if (typeof maxQuantity === 'object') {
          maxSize = maxQuantity;
        } else maxSize = { value: maxQuantity, label: maxQuantity.toString() };
        return this;
      },

      withAcceptType: function (types?: string[]) {
        if (types) acceptType = types;
        return this;
      },

      withRepeatedNameAccepted: function (isRepeated?: boolean) {
        if (isRepeated) {
          repeatedNameAccepted = isRepeated;
        }
        return this;
      },

      withMaxName: function (maxQuantity?: number) {
        if (maxQuantity) maxName = maxQuantity;
        return this;
      },

      withMaxDropedFile: function (maxQuantity?: number) {
        if (maxQuantity) maxDropedFile = maxQuantity;
        return this;
      },

      withTotalFiles: function (maxQuantity?: number) {
        if (maxQuantity) maxTotalFile = maxQuantity;
        return this;
      },

      validate: async () => {
        allFiles = [...fileBase];

        if (dropFile) {
          allFiles = allFiles.concat(Array.from(dropFile));
        }

        if (Array.from(dropFile).length > maxDropedFile) {
          notify(
            `Só é possível subir ${maxDropedFile} arquivo${
              maxDropedFile > 1 ? '(s) ' : ' '
            }por vez`
          );
          return;
        }

        if (maxTotalFile && Array.from(allFiles).length > maxTotalFile) {
          notify(`Só é possível subir ${maxTotalFile} arquivos`);
          return;
        }

        if (acceptType) {
          const noAcceptFile = !Array.from(allFiles).every((e: any) =>
            acceptType.includes(getExtensionFile(e.name))
          );
          if (noAcceptFile) {
            if (acceptType.length === 1) {
              notify(`Apenas o formato '${acceptType[0]}' é válido.`);
            } else {
              notify(
                `Apenas os formatos: '${acceptType.join("', '")}' são válidos.`
              );
            }
            return;
          }

          if (acceptType.includes('pdf')) {
            const result = await verifyIntegrity(dropFile);
            if (!result) return;
          }
        }

        if (repeatedNameAccepted) {
          const allName = allFiles.map((value) => value.name);
          const result = allName.some((item, index) => {
            return allName.indexOf(item) !== index;
          });
          if (result) {
            notify('Existem arquivos com mesmo nome');
            return;
          }
        }

        if (maxName !== 0) {
          const hasMaxFileName = Array.from(allFiles).some(
            (e: any) => e.name.length > maxName
          );
          if (hasMaxFileName) {
            notify(
              `O tamanho máximo permitido para o nome do arquivo é de ${maxName} caracteres.`
            );
            return;
          }
        }

        const total = getFileSize(allFiles);
        if (total > maxSize.value) {
          notify(`Tamanho máximo permitido é de ${maxSize.label} MB`);
          return;
        }
        return true;
      },
    }
  );
};
