import React, { useMemo } from 'react';

import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import { DrawerContainer } from './styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';

interface CustomDrawerProps extends DrawerProps {
  width: number;
  elevation: number;
  close: () => void;
  left?: number;
}

interface DrawerTitleProps {
  onClose: () => void;
}

const DrawerTitle: React.FC<DrawerTitleProps> = ({ onClose, children }) => {
  return (
    <Card
      elevation={1}
      style={{
        borderRadius: 0,
        minHeight: '65px',
        zIndex: 999999,
      }}
    >
      <Box p={1}>
        <Box display="flex" alignItems="center" gridGap={8}>
          {onClose && (
            <IconButton
              onClick={onClose}
              id={`close-drawer-${children}`}
              color="primary"
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography variant="h5">{children}</Typography>
        </Box>
      </Box>
    </Card>
  );
};

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  width,
  elevation,
  anchor,
  open,
  children,
  title,
  close,
  left,
}) => {
  //TODO: REMOVER A PROP LEFT NO FUTURO PARA IMPLEMENTAR O ANCHOR
  const actualAnchor = useMemo(() => {
    if (left !== undefined) {
      return 'left';
    }
    return anchor ?? 'right';
  }, [anchor, left]);
  return (
    <Drawer
      anchor={actualAnchor}
      open={open}
      PaperProps={{ style: { overflowY: 'hidden' } }}
      onClose={close}
    >
      <DrawerContainer
        elevation={elevation}
        style={{
          width: `${width}px`,
          left: left ?? 'unset',
        }}
      >
        <DrawerTitle onClose={close}>{title}</DrawerTitle>
        {children}
      </DrawerContainer>
      <Divider />
    </Drawer>
  );
};

export default CustomDrawer;
