import React from 'react';
import { styled } from '@material-ui/core/styles';
import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';

interface SkeletonCustomProps extends SkeletonProps {
  colorMain: string;
  colorMiddle: string;
}

const styles = (props: SkeletonCustomProps) => {
  return {
    background: `linear-gradient(92deg, ${props.colorMain}, ${props.colorMiddle}, ${props.colorMain})`,
    opacity: 0.8,

    '&.MuiSkeleton-text': {
      borderRadius: '14px',
      transform: 'none',
      height: '12px',
    },
  };
};

export const SkeletonCustom = styled(({ colorMain, colorMiddle, ...props }) => (
  <Skeleton {...props} />
))(styles);
