import React, { Suspense } from 'react';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import {
  AUClientLogin,
  AUProductTrailSuggestion,
  Envs,
  PRODUCT_IDS,
  browserHistory,
} from '@assertiva/assertiva-ui';

import HomePage from '@src/pages/Home';
import SignaturePage from '@src/pages/Signature';
import { sendAnalytics } from '@utils/analytics';
import { ReactComponent as Signatory } from 'assets/icons/signatory.svg';
import { EVENTS } from './constants/analytics';

const Container = ({ children }) => {
  return (
    <Box p={5} maxWidth="1188px" margin="auto">
      {children}
    </Box>
  );
};

const getRouter = () => {
  return [
    {
      id: 0,
      exact: true,
      path: '/',
      redirect: '/inicio',
      isPrivate: false,
      component: (props) => (
        <Suspense fallback={<div>Loading...</div>}>
          <AUClientLogin {...props} />
        </Suspense>
      ),
    },
    {
      id: 1,
      path: '/inicio',
      exact: true,
      label: 'Home',
      isPrivate: true,
      component: (props) => (
        <Suspense fallback={<div>Loading...</div>}>
          <Container>
            <AUProductTrailSuggestion />
            <div {...props}>
              <HomePage />
            </div>
          </Container>
        </Suspense>
      ),
    },
    {
      id: 2,
      productId: PRODUCT_IDS.ASSINATURAS,
      path: '/',
      label: 'Assertiva Assinaturas',
      isPrivate: true,
      exact: true,
      showInMenu: true,
      nested: [
        {
          id: 3,
          exact: true,
          path: '/assinaturas',
          label: 'Assinaturas',
          showInMenu: true,
          isPrivate: true,
          icon: (
            <SvgIcon>
              <Signatory />
            </SvgIcon>
          ),
          action: () => {
            sendAnalytics(EVENTS.CLICK_SIGNATURE);
            browserHistory.push('/assinaturas');
          },
          component: () => {
            return (
              <Container>
                <AUProductTrailSuggestion />
                <SignaturePage />
              </Container>
            );
          },
        },
        {
          id: 4,
          showInMenu: true,
          action: () => {
            window.location.href = `${
              Envs().PAINEL_URL
            }/gestao-da-conta/relatorios/assinaturas`;
          },
          label: 'Relatórios',
          icon: <DescriptionOutlinedIcon />,
          exact: true,
          isPrivate: true,
        },
      ],
    },
  ];
};

export default getRouter;
