import { IBasicStyle } from './../../../../models/basic-style';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface EnvelopeStatusTotalProps extends IBasicStyle {
  backgroundColor: string;
}

const contentStyles = (): CreateCSSProperties => {
  return {
    minWidth: '152px',
    color: '#666666',
  };
};

const totalStyles = (props: EnvelopeStatusTotalProps) => {
  return {
    color: props.backgroundColor,
    lineHeight: '24px',
  };
};

const iconStyles = (props): CreateCSSProperties => {
  return {
    position: 'absolute',
    width: '14px',
    height: '14px',
    top: '10px',
    right: '5px',
    fill: props.fill,
  };
};

const labelStyles = (): CreateCSSProperties => {
  return { fontWeight: 500, lineHeight: '16px' };
};

const selectedStyles = (): CreateCSSProperties => {
  return { textTransform: 'capitalize' };
};

export const EnvelopeCheckIcon = styled(CheckCircleOutlineIcon)(iconStyles);
export const EnvelopeStatusSelected = styled(Typography)(selectedStyles);
export const EnvelopeStatusLabel = styled(Typography)(labelStyles);
export const EnvelopeStatusTotal = styled(Typography)(totalStyles);
export const EnvelopeStatusContent = styled('div')(contentStyles);
