import Box from '@material-ui/core/Box';
import React from 'react';
import { EnvelopeLinearProgress } from '../../style';
import { DashcardContainer } from './style';

interface DashcardProps {
  id?: string;
  onClick?: () => void;
  color?: string;
}

const Dashcard: React.FC<DashcardProps> = ({
  children,
  color,
  onClick,
  id,
}) => {
  return (
    <DashcardContainer
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick();
      }}
      id={id}
    >
      <Box display={'flex'} width={1} height={1} flexDirection="column">
        <EnvelopeLinearProgress
          variant="determinate"
          progressColor={color}
          value={100}
        />
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Box>
      </Box>
    </DashcardContainer>
  );
};

export default Dashcard;
