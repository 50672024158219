import { EnvelopeStatusResponse } from './interfaces/envelope-status-response';
import { EnvelopStatusCount } from './../../../models/stores/signatures/envelope-status';
import {
  SendEnvelopeSignatoryModel,
  SendEnvelopeRequest,
} from './interfaces/send-envelope-request';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorHandled } from '@src/models/stores/error-handled';
import HttpClient from '@infra/http-client';
import { SignatureProfileResponse } from './interfaces/signature-profile-response';
import { SignatureProfileCombo } from '@src/models/stores/signatures/signature-profile-combo';
import { GlobalStore } from '@src/models/stores/global';
import { SignatureWizard } from '@src/models/stores/signatures/signature-wizard';
import {
  Signatory,
  SignatoryFormValue,
} from './interfaces/signatory-form-value';
import FileModel from '@src/models/components/file';
import regex from '@constants/regex';
import EnvelopeFilterModel from '@src/pages/Signature/models/envelope-filter';
import { GroupTablePagination } from '@src/models/stores/request/group/pagination';
import { EnvelopeGrid } from '@src/models/stores/signatures/envelope-grid';
import { EnvelopeGridResponse } from './interfaces/envelope-grid-response';
import mask from '@src/utils/DynamicFields/mask';
import { EnvelopeResponse } from './interfaces/envelope-response';
import {
  Envelope,
  EnvelopeDocument,
  EnvelopeSignatory,
} from '@src/models/stores/signatures/envelope';
import { StatusEnvelope } from '@src/utils/enum/status-envelope';
import { SignatoryStatusEnum } from '@src/utils/enum/status-signatory';
import {
  EnvelopeDocumentResponse,
  EnvelopeDocumentsResponse,
} from './interfaces/envelope-documents-response';
import KeyValue from '@src/models/key-value';
import { SignatoryLinkResponse } from './interfaces/signatory-link-response';
import { SignatorySearch } from '@src/models/stores/signatures/signatory-search';
import { SignatorySearchResponse } from './interfaces/signatory-search-response';
import { CancelToken } from 'axios';
import { StringUtils } from '@assertiva/assertiva-ui';
const { REACT_APP_SIGNATURE_BASE_URL } = process.env;

interface EnvelopeFilterPagination {
  value: EnvelopeFilterModel;
  page?: number;
  rowsPerPage?: number;
}

const initialState: SignatureWizard = {
  signatureProfiles: [],
  pagination: { rowsPerPage: 10, page: 0, count: 0 },
};

const signatoryClient = HttpClient('signatarios', REACT_APP_SIGNATURE_BASE_URL);
const envelopeClient = HttpClient('envelopes', REACT_APP_SIGNATURE_BASE_URL);

export const fetchSignatureProfiles = createAsyncThunk<
  SignatureProfileCombo[],
  void,
  { rejectValue: ErrorHandled }
>('signature-wizard/signature-profiles', async (_, { rejectWithValue }) => {
  const response = await signatoryClient.get<{
    perfis: SignatureProfileResponse[];
  }>({
    action: 'perfis-acesso',
    loading: false,
  });

  if (response.data && response.status === 200) {
    const { perfis } = response.data;
    const signatureProfiles = perfis.map<SignatureProfileCombo>((x) => {
      return {
        key: x.chave,
        value: x.valor,
      };
    });
    return signatureProfiles;
  }

  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar as funcionalidades.`,
  });
});

export const searchSignatures = createAsyncThunk<
  SignatorySearch[] | undefined,
  { name: string; cancelToken?: CancelToken },
  { rejectValue: ErrorHandled }
>('signature-wizard/signatures-search', async ({ name, cancelToken }) => {
  const response = await signatoryClient.get<{
    signatarios: SignatorySearchResponse[];
  }>({
    params: { nome: name },
    action: '',
    cancelToken,
    loading: false,
  });

  try {
    if (response?.data && response?.status === 200) {
      const { signatarios } = response.data;
      const signatures = signatarios.map<SignatorySearch>((signature) => {
        return {
          name: signature.nome,
          document: mask.document({ value: signature.documento.toString() }),
          email: signature.email,
          phone: signature.celular
            ? mask.phone({ value: signature.celular.toString() })
            : undefined,
        };
      });
      return signatures;
    } else if (response?.status === 204) {
      return [];
    }
  } catch (error) {
    return [];
  }
});

export const resendSignatory = createAsyncThunk<boolean, string>(
  'signature-wizard/resend-signatory',
  async (id) => {
    const response = await signatoryClient.put({
      action: `${id}/reenviar-link`,
      body: {},
      loading: false,
    });

    return response.data && response.status === 200;
  }
);

export const fetchSignatoryLink = createAsyncThunk<
  string,
  string,
  { rejectValue: ErrorHandled }
>('signature-wizard/get-signatory-link', async (id, { rejectWithValue }) => {
  const response = await signatoryClient.get<SignatoryLinkResponse>({
    action: `${id}/obter-link`,
    loading: false,
  });

  if (response.data && response.status === 200) {
    const { url } = response.data;
    return url;
  }

  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar o link do signatorio.`,
  });
});

export const fetchEnvelopeStatus = createAsyncThunk<
  EnvelopStatusCount[],
  void,
  { rejectValue: ErrorHandled }
>('signature-wizard/envelope-status', async (_, { rejectWithValue }) => {
  const response = await envelopeClient.get<EnvelopeStatusResponse>({
    action: 'contador-status',
    loading: false,
  });

  if (response.data && response.status === 200) {
    const { contadores } = response.data;
    const status = contadores.map<EnvelopStatusCount>((count) => {
      return {
        id: count.status,
        label: count.statusLabel,
        total: count.total,
      };
    });
    return status;
  }

  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar os status dos envelopes.`,
  });
});

export const fetchEnvelopes = createAsyncThunk<
  GroupTablePagination<EnvelopeGrid>,
  EnvelopeFilterPagination | undefined,
  { rejectValue: ErrorHandled }
>(
  'signature-wizard/envelopes',
  async (filter, { rejectWithValue, getState }) => {
    const state = getState() as GlobalStore;
    const response = await envelopeClient.get<EnvelopeGridResponse>({
      action: 'filtrar',
      params: JSON.parse(
        JSON.stringify({
          index:
            filter?.page === 0 || filter?.page === undefined ? 1 : filter?.page,
          envelope: filter?.value.envelope,
          email: filter?.value.mail,
          idUsuario: filter?.value.user?.id,
          criacaoInicio: filter?.value.createStartDate?.toISOString(),
          criacaoFim: filter?.value.createEndDate?.toISOString(),
          assinaturaInicio: filter?.value.signatureStartDate?.toISOString(),
          assinaturaFim: filter?.value.signatureEndDate?.toISOString(),
          documento: filter?.value.document?.replace(
            regex.specialCharactes,
            ''
          ),
          status: filter?.value.status,
          size: filter?.rowsPerPage
            ? filter.rowsPerPage
            : state.signatureWizard.pagination.rowsPerPage,
        })
      ),
      loading: false,
    });

    if (response.data && response.status === 200) {
      const { envelopes: envelopesResponse, total } = response.data;
      const envelopes = envelopesResponse.map<EnvelopeGrid>((item) => {
        return {
          date: item.dataHora,
          name: item.nome,
          documents: item.signatarios
            .map((item) => mask.document({ value: item?.documento }))
            .join(' • '),
          id: item.envelope,
          total: `${item.totalAssinados}/${item.totalSignatarios} assinados`,
          status: item.status,
          statusLabel: item.statusLabel,
          hasMultipleSignatures: item.totalSignatarios > 1,
        };
      });
      return {
        count: total,
        page: filter?.page ?? state.signatureWizard.pagination.page,
        rowsPerPage:
          filter?.rowsPerPage ?? state.signatureWizard.pagination.rowsPerPage,
        groupGrid: envelopes,
      };
    }

    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar os envelopes.`,
    });
  }
);

export const fetchEnvelope = createAsyncThunk<
  Envelope,
  string,
  { rejectValue: ErrorHandled }
>('signature-wizard/envelope', async (id, { rejectWithValue, getState }) => {
  const response = await envelopeClient.get<EnvelopeResponse>({
    action: id,
    loading: false,
  });

  if (response.data && response.status === 200) {
    const {
      dataHora,
      documentos,
      envelope,
      mensagemStatus,
      permiteCancelarEnvelope,
      permiteReenviarLinks,
      signatarios,
      status,
      totalAssinados,
      totalSignatarios,
      nome,
    } = response.data;
    const documents = documentos.map<EnvelopeDocument>((document) => {
      return {
        status: status as StatusEnvelope,
        statusMessage: mensagemStatus,
        documentType: document.tipoDocumento,
        id: document.id,
        date: dataHora,
        name: document.nome,
      };
    });

    const isPriority = !signatarios.every(
      (e) => e.prioridade === signatarios[0].prioridade
    );

    const signatories = signatarios.map<EnvelopeSignatory>((signatory) => {
      return {
        id: signatory.id,
        isPriority: isPriority,
        priority: signatory.prioridade,
        name: signatory.nome,
        shippingMethod: signatory.formaEnvio,
        status: signatory.status as SignatoryStatusEnum,
        statusMessage: signatory.mensagemStatus,
        thereIsCopy: signatory.permiteCopiarLink,
        thereIsResend: signatory.permiteReenviarLink,
        document: StringUtils.formatDocument(signatory.documento),
        email: signatory.email,
        phone: StringUtils.formatPhone(signatory.celular),
        signatureProfile: signatory.perfilAssinatura,
      };
    });
    return {
      id: envelope,
      name: nome,
      date: dataHora,
      status: status as StatusEnvelope,
      statusMessage: mensagemStatus,
      thereIsCancel: permiteCancelarEnvelope,
      thereIsResend: permiteReenviarLinks,
      totalSignature: totalSignatarios,
      totalSigned: totalAssinados,
      documents,
      signatories,
    };
  }

  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar os status dos envelopes.`,
  });
});

export const cancelEnvelope = createAsyncThunk<boolean, string>(
  'signature-wizard/cancel-envelope',
  async (id) => {
    const response = await envelopeClient.put({
      action: `${id}/cancelar`,
      body: {},
      loading: false,
    });

    return response.data && response.status === 200;
  }
);

export const resendEnvelope = createAsyncThunk<boolean, string>(
  'signature-wizard/resend-envelope',
  async (id) => {
    const response = await envelopeClient.put({
      action: `${id}/reenviar-link`,
      body: {},
      loading: false,
    });

    return response.data && response.status === 200;
  }
);

export const fetchAllDocumentsEnvelope = createAsyncThunk<
  KeyValue[],
  { id: string; withAudit: boolean },
  { rejectValue: ErrorHandled }
>(
  'signature-wizard/all-documents-envelope',
  async ({ id, withAudit }, { rejectWithValue }) => {
    const response = await envelopeClient.get<EnvelopeDocumentsResponse>({
      action: `${id}/links-documentos-assinados`,
      params: { auditoria: withAudit },
      loading: false,
    });
    if (response.data && response.status === 200) {
      const links = response.data.links.map<KeyValue>((file) => {
        return {
          id: file.chave,
          value: file.url,
        };
      });
      return links;
    }
    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar links.`,
    });
  }
);

export const fetchDocumentEnvelope = createAsyncThunk<
  KeyValue,
  { idEnvelope: string; idDocument: string },
  { rejectValue: ErrorHandled }
>(
  'signature-wizard/document-envelope',
  async ({ idDocument, idEnvelope }, { rejectWithValue }) => {
    const response = await envelopeClient.get<EnvelopeDocumentResponse>({
      action: `${idEnvelope}/documentos/${idDocument}/link-download`,
      params: { auditoria: true },
      loading: false,
    });
    if (response.data && response.status === 200) {
      const link = response.data.link;
      return { id: link.chave, value: link.url };
    }
    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar o link.`,
    });
  }
);

export const postEnvelope = createAsyncThunk<
  string,
  {
    files: FileModel[];
    name?: string;
    signatories: Signatory[];
  },
  { rejectValue: ErrorHandled }
>(
  'signature-wizard/send-envelope',
  async ({ files, signatories, name }, { rejectWithValue }) => {
    const body = generateSendEnvelopeBody(files, signatories, name);
    const response = await envelopeClient.post({
      action: '',
      body: body,
      loading: true,
    });

    if (!response.data || response.status !== 201) {
      return rejectWithValue({
        errorMessage: `Ocorreu um erro ao criar o envelope, tente novamente, caso o erro persista entre em contato com nosso suporte.`,
      });
    }

    return response.data.id;
  }
);

const mapSignatoryToPortuguese = (sign: Signatory) => {
  const { priority, ...other } = sign;
  return {
    ...mapSignatoryFormToPortuguese({ ...other }),
    prioridade: priority,
  };
};

const mapSignatoryFormToPortuguese = (sign: SignatoryFormValue) => ({
  nome: sign.name,
  documento: sign.document.replace(regex.specialCharactes, ''),
  celular: sign.phone.replace(regex.specialCharactes, ''),
  email: sign.email,
  perfilAssinatura: sign.signatureProfile,
  formaEnvio: sign.shippingMethod,
  envioPrioritario: false,
});

const generateSendEnvelopeBody = (
  files: FileModel[],
  signatories: Signatory[],
  name?: string
): SendEnvelopeRequest => {
  const documentos = files.map((file) => {
    return {
      chave: file.key ?? '',
      nome: file.name,
    };
  });

  const signatarios: SendEnvelopeSignatoryModel[] = signatories.map(
    mapSignatoryToPortuguese
  );

  return {
    documentos,
    signatarios,
    nome: name,
  };
};

export const putSignatory = createAsyncThunk<
  string,
  {
    signatoryFormValue: SignatoryFormValue;
  },
  { rejectValue: ErrorHandled }
>(
  'signature-wizard/put-signatory',
  async ({ signatoryFormValue }, { rejectWithValue }) => {
    const response = await signatoryClient.put({
      action: signatoryFormValue.id,
      body: mapSignatoryFormToPortuguese(signatoryFormValue),
      loading: true,
    });

    if (!response.data || response.status !== 204) {
      return rejectWithValue({
        errorMessage: `Ocorreu um erro ao editar o signatário, tente novamente, caso o erro persista entre em contato com nosso suporte.`,
      });
    }

    return response.data;
  }
);

export const signatureWizardSlice = createSlice({
  name: 'signature-wizard',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSignatureProfiles.fulfilled, (state, { payload }) => {
      if (payload) state.signatureProfiles = payload;
    });
    builder.addCase(fetchEnvelopes.fulfilled, (state, { payload }) => {
      const { count, page, rowsPerPage } = payload;
      state.pagination = { count, page, rowsPerPage };
    });
  },
});

export default signatureWizardSlice.reducer;
export const selectEnvelopePagination = (state: GlobalStore) =>
  state.signatureWizard.pagination;
export const selectSignatureProfiles = (state: GlobalStore) =>
  state.signatureWizard.signatureProfiles;
