import Chip from '@material-ui/core/Chip';
import { styled } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

const titleStyles = (): CreateCSSProperties => {
  return {
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '15px',
    color: '#232F34',

    '& .MuiChip-deleteIcon': {},
  };
};

const chipStyles = (): CreateCSSProperties => {
  return {
    '& .MuiChip-deleteIcon': {
      color: 'black',
    },
  };
};

export const EnvelopeLabel = styled('span')(titleStyles);
export const EnvelopeFilterChip = styled(Chip)(chipStyles);
