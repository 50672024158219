import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';

export const TableRowCustom = styled(TableRow)`
  &&& {
    &:hover {
      .action {
        visibility: visible;
      }
    }

    .action {
      visibility: hidden;
    }

    .action-button {
      &:hover {
        background-color: #f3f3f3;
        background: #f3f3f3;
      }
    }
  }
`;
