import { createGlobalStyle } from 'styled-components';
import { styledTheme as Theme } from './theme/default';

export const GlobalStyle = createGlobalStyle`    
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  u, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  section::-webkit-scrollbar {
    display: none;
  }

  body {
    line-height: 1;
    font-family: ${() => Theme.font.family};
    color: ${() => Theme.colors.black};
  }
  
  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *, :after, :before {
    box-sizing: border-box;
    border-style: solid;
    border-color: ${() => Theme.colors.gray100};
    border-width: 0;
  }

  button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.25;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${() => Theme.colors.primaryBlue}
  }

  html {
    --scrollbarBG: ${() => Theme.colors.gray100};
    --thumbBG: ${() => Theme.colors.gray500};
  }

  div::-webkit-scrollbar, section::-webkit-scrollbar, ul::-webkit-scrollbar {
    width: 11px;
    height: 10px;
    border-radius: 4px;
  }
  
  div, section, ul {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  
  div::-webkit-scrollbar-track, section::-webkit-scrollbar-track, ul::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  
  div::-webkit-scrollbar-thumb, section::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 4px;
    border: 3px solid var(--scrollbarBG);
  }

  :root {
    --topBarHeight: 64px;
    --sidebarWidthClosed: 54px;
    --sidebarWidthOpen: 225px;
  }
`;
