import React from 'react';
import { ThemeProvider } from 'styled-components';
import { styledTheme } from './theme/default';
import { Redirect } from 'react-router-dom';

import Routes from 'routes';

import { AURoutesGenerator } from '@assertiva/assertiva-ui';

import Office from './AppLayout';

import ErrorInterceptor from '@hoc/error';
import { StylesProvider } from '@material-ui/core/styles';

function PrivateRoute() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={styledTheme}>
        <ErrorInterceptor>
          <AURoutesGenerator
            routes={[...Routes()]}
            defaultPrivateRedirect="/"
            defaultPublicRedirect="/"
            NotFoundComponent={() => <Redirect to="/" />}
            PrivateLayout={(props) => <Office {...props} />}
          />
        </ErrorInterceptor>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default PrivateRoute;
