import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Column from '@models/components/data/column';
import { useTheme } from '@material-ui/core/styles';
import { TableContainerCustom, TableContentCustom } from './TableContainer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Pagination } from '@models/pagination';
import Box from '@material-ui/core/Box';
import { AUEmptyData } from '@assertiva/assertiva-ui';
import PaginationCustom from './Pagination';
import { TableRowCustom } from './Row';
import Skeleton from '@components/Skeleton/Wrapper';

export interface AccessorAction<T> {
  acessor: string;
  action?: (act: T) => void;
}

interface TableProps<T> {
  columns: Column<T>[];
  rows: T[];
  onClickRow?: (row: T) => void;
  pagination: Pagination;
  actionCell?: AccessorAction<T>[];
  handlePagination: (page: number, rowsPerPage: number) => void;
  isLoading?: boolean;
}

const TableCustom: <T>(
  p: TableProps<T>
) => React.ReactElement<TableProps<T>> = ({
  columns,
  rows,
  pagination,
  handlePagination,
  onClickRow,
  actionCell,
  isLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <TableContainerCustom
      component={Paper as any}
      width={isMobile ? '100%' : 'max-width'}
    >
      <TableContentCustom>
        <TableHead>
          <TableRow>
            {columns.map(
              (column) =>
                column.active && (
                  <TableCell key={column.label} {...column.headCellProps}>
                    {column.label}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return isLoading ? (
              <TableRowCustom key={`${index}-th`}>
                <TableCell colSpan={9}>
                  <Skeleton height="2em" width="100%" variant="text" />
                </TableCell>
              </TableRowCustom>
            ) : (
              <TableRowCustom
                style={{ cursor: onClickRow ? 'pointer' : 'normal' }}
                hover
                key={`${index}-th`}
              >
                {columns.map((column) => {
                  const action =
                    actionCell && actionCell.length > 0
                      ? actionCell.find(
                          (action) => action.acessor === column.accessor
                        )
                      : undefined;
                  const Component = column.component as any;

                  return (
                    column.active && (
                      <TableCell
                        id={`table-${column.accessor}-${index}`}
                        classeName="action"
                        onClick={() => {
                          onClickRow && !action && onClickRow(row);
                        }}
                        key={`${column.accessor}-th`}
                        {...(column.bodyCellProps ? column.bodyCellProps : {})}
                      >
                        <Component
                          onClick={action ? action.action : () => {}}
                          row={row}
                        >
                          {row[column.accessor]}
                        </Component>
                      </TableCell>
                    )
                  );
                })}
              </TableRowCustom>
            );
          })}
        </TableBody>
      </TableContentCustom>
      {pagination.count > 0 ? (
        <PaginationCustom
          pagination={pagination}
          handlePagination={handlePagination}
        />
      ) : (
        <Box pb={2}>
          <AUEmptyData title="Nenhum registro encontrado." />
        </Box>
      )}
    </TableContainerCustom>
  );
};

export default TableCustom;
