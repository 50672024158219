import { IBasicStyle } from '@models/basic-style';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';

const iconButtonStyles = (props: IBasicStyle): CreateCSSProperties => {
  return {
    width: 24,
    height: 24,
    padding: 0,
    backgroundColor: props.theme.palette.grey[200],

    '&:hover': {
      backgroundColor: props.theme.palette.grey[300],
    },
  };
};

export const EnvelopeIconButton = styled(IconButton)(iconButtonStyles);
