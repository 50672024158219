import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { SubtitleSignatureHeader } from './style';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

interface SignatureHeaderProps {
  onClick?: () => void;
}

const SignatureHeader = ({ onClick }: SignatureHeaderProps) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h5">Assinaturas de documentos</Typography>
        <SubtitleSignatureHeader variant="body1">
          Aqui você pode acompanhar todas as assinaturas enviadas e finalizadas
          pelos signatários.
        </SubtitleSignatureHeader>
      </Box>
      <Box>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          key="send-envelope-button"
          onClick={onClick}
          startIcon={<AddIcon />}
        >
          Nova assinatura
        </Button>
      </Box>
    </Box>
  );
};

export default SignatureHeader;
