import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DeleteSweep from '@material-ui/icons/DeleteSweep';
import IconButton from '@material-ui/core/IconButton';
import { BUDrawerSendEnvelope } from '@assertiva/business-ui';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@src/stores';
import {
  fetchEnvelopes,
  fetchEnvelopeStatus,
  fetchSignatureProfiles,
  selectEnvelopePagination,
} from '@src/stores/signatures/signature-wizard';
import { AUBoxWrapper } from '@assertiva/assertiva-ui';

import SignatureHeader from './components/SignatureHeader';
import Dashcard from './components/Dashcard';
import UpdateIcon from '@material-ui/icons/Update';
import { unwrapResult } from '@reduxjs/toolkit';
import { EnvelopStatusCount } from '@src/models/stores/signatures/envelope-status';
import EnvelopeStatusSection from './components/EnvelopeStatusSection';
import { immutableSplice } from '@src/utils/array-utils';
import EnvelopeFilterModel from './models/envelope-filter';
import FilterButton from '@src/components/FilterTriggerButton';
import EnvelopeFilter from './components/EnvelopeFilter';
import EnvelopeChipFilter from './components/EnvelopeChipFilter';
import { GroupTablePagination } from '@src/models/stores/request/group/pagination';
import { EnvelopeGrid } from '@src/models/stores/signatures/envelope-grid';
import TableCustom, { AccessorAction } from '@src/components/Table';
import { envelopeColumns } from './data/column';
import Skeleton from '@components/Skeleton/Wrapper';
import { ErrorHandled } from '@src/models/stores/error-handled';
import EnvelopeDetail from './components/EnvelopeDetail';
import { ReactComponent as NewSignatureIcon } from 'assets/new-signature.svg';
import { SubtitleSignatureHeader } from './components/SignatureHeader/style';
import { sendAnalytics } from '@utils/analytics';
import { EVENTS } from './constants/analytics';
import { filterChipsLabels, mountAnalyticsData, mountDate } from './utils';
import { EnvelopeDetailInitial } from './models/envelope-detail';
import { FilterTag } from './models/filter-tag';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation } from 'react-router-dom';
import QueryString from 'qs';

const initialStateGrid: GroupTablePagination<EnvelopeGrid> = {
  groupGrid: [],
  rowsPerPage: 10,
  page: 0,
  count: 10,
};
const SignaturePage = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [envelopeDetailInitial, setEnvelopeDetailInitial] =
    useState<EnvelopeDetailInitial>();
  const [isLoadingDashcard, setIsLoadingDashcard] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [gridPagination, setGridPagination] =
    useState<GroupTablePagination<EnvelopeGrid>>(initialStateGrid);

  const [filterValues, setFilterValues] = useState<EnvelopeFilterModel>();
  const [thisOpenSendEnvelope, setThisOpenSendEnvelop] = useState(false);
  const [envelopesStatus, setEnvelopesStatus] = useState<EnvelopStatusCount[]>(
    []
  );

  const selectedStatus = useMemo(
    () => envelopesStatus.find((status) => status.selected),
    [envelopesStatus]
  );

  const thereIsEnvelope = useMemo(() => {
    if (envelopesStatus.length > 0) {
      return envelopesStatus.every((e) => e.total !== 0);
    }
  }, [envelopesStatus]);

  const location = useLocation();

  const { envelope } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const columns = envelopeColumns();

  const dispatch: AppDispatch = useDispatch();
  const pagination = useSelector(selectEnvelopePagination);

  useEffect(() => {
    dispatch(fetchSignatureProfiles());
    dispatch(fetchEnvelopeStatus())
      .then(unwrapResult)
      .then((result) => {
        setEnvelopesStatus(result);
        setIsLoadingDashcard(false);
      })
      .catch(() => setIsLoadingDashcard(false));
    if (!envelope) {
      dispatch(fetchEnvelopes())
        .then(unwrapResult)
        .then((result) => {
          setGridPagination(result);
          setIsLoadingTable(false);

          setFirstLoading((old) => {
            if (old) return false;
            return old;
          });
        })
        .catch(() => setIsLoadingTable(false));
    }
  }, [dispatch, envelope]);

  const handleOpenSendEnvelop = () => {
    sendAnalytics(EVENTS.CLICK_NEW_SIGNATURE_BUTTON);
    setThisOpenSendEnvelop(true);
  };
  const handleCloseSendEnvelop = () => {
    handleRefresh();
    setThisOpenSendEnvelop(false);
  };

  const handleSelectStatus = (id?: string) => {
    setEnvelopesStatus((oldStatus) => {
      setIsLoadingTable(true);
      if (id) {
        const index = oldStatus.findIndex((e) => e.id === id);
        const unselectedStatus = immutableSplice(oldStatus, index, 1).map(
          (old) => {
            return { ...old, selected: false };
          }
        );
        const newStatus = oldStatus[index];

        sendAnalytics({
          ...EVENTS.CLICK_SIGNATURE_STATUS_CARD,
          eventCategory: EVENTS.CLICK_SIGNATURE_STATUS_CARD.eventCategory(
            newStatus.label
          ),
        });

        unselectedStatus.splice(index, 0, {
          ...newStatus,
          selected: !!!newStatus.selected,
        });
        handleSearch(
          {
            ...filterValues,
            status: !!!newStatus.selected ? newStatus.id : undefined,
          },
          1
        );

        return unselectedStatus;
      } else {
        sendAnalytics({
          ...EVENTS.CLICK_SIGNATURE_STATUS_CARD,
          eventCategory:
            EVENTS.CLICK_SIGNATURE_STATUS_CARD.eventCategory('todos'),
        });
        handleSearch({ ...filterValues }, 1);
        const newStatus = oldStatus.map((old) => {
          return { ...old, selected: false };
        });
        return newStatus;
      }
    });
  };

  const handleClickFilterButton = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSearchEnvelopeStatus = async () => {
    setIsLoadingDashcard(true);
    const result = await dispatch(fetchEnvelopeStatus());

    if (result.payload && !(result.payload instanceof ErrorHandled)) {
      const status = result.payload;
      if (selectedStatus) {
        const index = status.findIndex((e) => e.id === selectedStatus.id);
        status[index].selected = true;
      }
      setEnvelopesStatus(status);
    }
    setIsLoadingDashcard(false);
  };

  const handleSearch = useCallback(
    async (
      filter: EnvelopeFilterModel,
      page?: number,
      rowsPerPage?: number
    ) => {
      setIsLoadingTable(true);
      const result = await dispatch(
        fetchEnvelopes({ value: filter, page, rowsPerPage })
      );

      if (result.payload && !(result.payload instanceof ErrorHandled)) {
        setGridPagination(result.payload);
      }
      setIsLoadingTable(false);
    },
    [dispatch]
  );

  const handleFilter = useCallback(
    (filter: EnvelopeFilterModel) => {
      mountAnalyticsData(filter);
      setFilterValues(filter);
      handleSearch({ ...filter, status: selectedStatus?.id }, 1);
      setOpenFilter(false);
      setFirstLoading((old) => {
        if (old) return false;
        return old;
      });
    },
    [handleSearch, selectedStatus?.id]
  );

  useEffect(() => {
    if (envelope) {
      handleFilter({ envelope } as any);
    }
  }, [envelope, handleFilter]);

  const handleRemoveFilter = (propert: string) => {
    setFilterValues((old) => {
      if (propert === 'createStartDate') {
        const result = {
          ...old,
          createStartDate: undefined,
          createEndDate: undefined,
        };
        handleSearch({ ...result, status: selectedStatus?.id }, 1);
        return result;
      }
      if (propert === 'signatureStartDate') {
        const result = {
          ...old,
          signatureStartDate: undefined,
          signatureEndDate: undefined,
        };
        handleSearch({ ...result, status: selectedStatus?.id }, 1);
        return result;
      } else if (propert === 'status' && selectedStatus) {
        setEnvelopesStatus((oldStatus) => {
          const index = oldStatus.findIndex((e) => e.id === selectedStatus.id);
          if (index >= 0) oldStatus[index].selected = false;
          handleSearch({ ...old }, 1);
          return [...oldStatus];
        });
      } else if (old) {
        old[propert] = undefined;
        handleSearch({ ...old, status: selectedStatus?.id }, 1);
      }

      return { ...old };
    });
  };

  const handlePagination = (page: number, rowsPerPage: number) => {
    handleSearch(
      { ...filterValues, status: selectedStatus?.id },
      page,
      rowsPerPage
    );
  };

  const handleRefresh = () => {
    sendAnalytics(EVENTS.CLICK_SIGNATURE_UPDATE_CARD);
    handleSearchEnvelopeStatus();
    handleSearch({ ...filterValues, status: selectedStatus?.id }, 1);
  };

  const handleCloseDetail = () => {
    setEnvelopeDetailInitial(undefined);
  };

  const actions: AccessorAction<EnvelopeGrid>[] = [
    {
      acessor: 'action',
      action: (item) => {
        setEnvelopeDetailInitial({ id: item.id, name: item.name });
      },
    },
  ];

  const mountTable = () => {
    if (firstLoading) {
      return <Skeleton width="100%" height={731} />;
    } else if (gridPagination.groupGrid && thereIsEnvelope) {
      return (
        <TableCustom<EnvelopeGrid>
          rows={gridPagination.groupGrid}
          columns={columns}
          actionCell={actions}
          pagination={pagination}
          handlePagination={handlePagination}
          isLoading={isLoadingTable}
        />
      );
    } else {
      return (
        <Card>
          <CardActionArea onClick={handleOpenSendEnvelop}>
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '85px 0px',
              }}
            >
              <NewSignatureIcon />
              <Box mt={2}>
                <SubtitleSignatureHeader variant="body1">
                  Você ainda não enviou nenhum documento para assinatura
                </SubtitleSignatureHeader>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }
  };

  const filter = useMemo(() => {
    return { ...filterValues, status: selectedStatus?.label };
  }, [filterValues, selectedStatus?.label]);

  const filterTags = useMemo(() => {
    if (filter) {
      const values: FilterTag[] = [];
      const keys = Object.keys(filter);
      keys.forEach((key) => {
        const value = filter[key];
        if (key.includes('StartDate')) {
          const createDateValue = mountDate(
            key,
            'createStartDate',
            filter.createStartDate,
            filter.createEndDate
          );

          if (createDateValue) values.push(createDateValue);

          const signatureDateValue = mountDate(
            key,
            'signatureStartDate',
            filter.signatureStartDate,
            filter.signatureEndDate
          );

          if (signatureDateValue) values.push(signatureDateValue);
        } else if (!key.includes('EndDate') && value) {
          values.push({
            id: key,
            label: filterChipsLabels[key],
            value: key === 'user' ? value.name : value,
          });
        }
      });
      return values;
    }
    return [];
  }, [filter]);

  const eraseAllFilters = () =>
    filterTags.forEach(({ id }) => handleRemoveFilter(id));

  return (
    <AUBoxWrapper>
      <Paper style={{ padding: '32px' }}>
        <SignatureHeader onClick={handleOpenSendEnvelop} />
        <EnvelopeStatusSection
          isLoading={isLoadingDashcard}
          selectedStatus={selectedStatus}
          onSelectStatus={handleSelectStatus}
          status={envelopesStatus}
        >
          <Tooltip title="Atualizar">
            <span>
              <Dashcard onClick={handleRefresh} id="dashcard-refresh">
                <UpdateIcon color="primary" />
              </Dashcard>
            </span>
          </Tooltip>
        </EnvelopeStatusSection>
        <Box display="flex" justifyContent="end" mt={2} gridGap={4}>
          <FilterButton onClick={handleClickFilterButton} />
          {filterTags.length ? (
            <IconButton onClick={eraseAllFilters} id="btn-limpar-tudo-filtros">
              <DeleteSweep color="primary" />
            </IconButton>
          ) : null}
        </Box>
        <Box mt={1} mb={2}>
          <EnvelopeChipFilter
            filterTags={filterTags}
            onRemove={handleRemoveFilter}
          />
        </Box>
        {mountTable()}
      </Paper>
      <BUDrawerSendEnvelope
        open={thisOpenSendEnvelope}
        onClose={handleCloseSendEnvelop}
      />
      <EnvelopeFilter
        open={openFilter}
        onClose={handleCloseFilter}
        onFilter={handleFilter}
        defaultValues={filterValues}
      />
      <EnvelopeDetail
        envelopeDetailInitial={envelopeDetailInitial}
        onClose={handleCloseDetail}
      />
    </AUBoxWrapper>
  );
};

export default SignaturePage;
