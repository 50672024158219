import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AUNotifier } from '@assertiva/assertiva-ui';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const notify = (message: string, isIcon?: boolean) => {
  const actionButton = (key) => (
    <Button
      color="secondary"
      size="small"
      onClick={() => {
        AUNotifier.closeNotificationByKey(key);
      }}
    >
      <Typography
        style={{
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '15px',
          textAlign: 'right',
          letterSpacing: '0.5px',
          color: '#BB86FC',
        }}
      >
        ENTENDI
      </Typography>
    </Button>
  );

  const actionIcon = (key) => {
    return (
      <>
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => {
            AUNotifier.closeNotificationByKey(key);
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
    );
  };

  AUNotifier.notify({
    message,
    options: {
      preventDuplicate: true,
      autoHideDuration: 3000,
      variant: 'default',
      action: isIcon ? actionIcon : actionButton,
    },
  });
};
