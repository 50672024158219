import axios from 'axios';
import { notify } from '@utils/notification';
import { AULoadingManager } from '@assertiva/assertiva-ui';

export const textToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const exportDocumentHandler = async (
  url,
  name,
  extension = 'pdf',
  withoutLoading
) => {
  try {
    const instance = axios.create();
    if (!!!withoutLoading) AULoadingManager.show();

    const response = await instance({
      url: url,
      method: 'GET',
      responseType: 'blob',
    });

    const src = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = src;
    const newName = name.split('.')[1] ? name : `${name}.${extension}`;
    link.setAttribute('download', `${newName}`);
    document.body.appendChild(link);
    link.click();
    AULoadingManager.close();
  } catch (error) {
    AULoadingManager.close();
  }
};

export const handleDownloadBlob = (blob, filename) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    notify('Download realizado com sucesso.');
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((y, f) => {
      return f(y);
    }, x);
