import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import { FilterTag } from '../../models/filter-tag';
import { EnvelopeLabel } from './style';

interface EnvelopeChipFilterProps {
  filterTags: FilterTag[];
  onRemove: (id: string) => void;
}

const EnvelopeChipFilter = ({
  filterTags,
  onRemove,
}: EnvelopeChipFilterProps) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="end"
      mt={1}
      style={{ gap: '8px' }}
    >
      {filterTags.length > 0 && <EnvelopeLabel>Filtros:</EnvelopeLabel>}
      {filterTags.map((tag) => {
        return (
          <Chip
            id={`chip-${tag.id}`}
            key={tag.id}
            size="small"
            color="primary"
            label={
              <span>
                <b>{tag.label}</b>: {tag.value}
              </span>
            }
            onDelete={() => {
              onRemove(tag.id);
            }}
          />
        );
      })}
    </Box>
  );
};

export default EnvelopeChipFilter;
