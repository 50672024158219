import React, { createRef, useCallback, useEffect, useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import { ButtonHeaderPdf, DialogHeaderPdf, RegionCountPagePdf } from './style';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileModel from '@src/models/components/file';
import { handleDownloadBlob } from '@src/utils/utils';
import OverflowTip from '../OverflowTip';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

interface PdfViewProps {
  open: boolean;
  file: FileModel;
  onClose: () => void;
}

const PdfView = ({ open, file, onClose }: PdfViewProps) => {
  const [numPages, setNumPages] = useState(0);
  const [actualPage, setActualPage] = useState(1);

  const ref = createRef<HTMLDivElement>();

  const onDocumentLoadSuccess = (parms: any) => {
    setNumPages(parms.numPages);
  };

  const handleClose = () => {
    onClose();
    setNumPages(0);
    setActualPage(1);
  };

  const handleCalcPage = useCallback(
    (el) => {
      if (ref) {
        const overallPdfHeight = el.target.scrollHeight / numPages;
        const overallActualPage = el.target.scrollTop / overallPdfHeight;
        setActualPage(Math.round(overallActualPage) + 1);
      }
    },
    [ref, numPages]
  );

  useEffect(() => {
    const actualRef = { ...ref };
    if (numPages > 0) {
      actualRef.current?.addEventListener('scroll', handleCalcPage);

      return () =>
        actualRef.current?.removeEventListener('scroll', handleCalcPage);
    }
  }, [ref, numPages, handleCalcPage]);

  const downloadFile = async () => {
    if (file && file.fileForm) {
      const arrayBuffer = await file.fileForm.arrayBuffer();
      handleDownloadBlob(new Blob([arrayBuffer]), file.name);
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      }}
      fullScreen
      open={open}
    >
      <DialogHeaderPdf>
        <IconButton
          id={'button-close-pdfview'}
          onClick={handleClose}
          aria-label="back"
        >
          <ArrowBackIcon style={{ color: 'white' }} />
        </IconButton>{' '}
        <OverflowTip
          customStyle={{
            flex: 1,
            textAlign: 'center',
            marginLeft: '100px',
            color: 'white',
          }}
          value={file?.name}
        />
        <ButtonHeaderPdf
          id="button-download-pdfview"
          color="default"
          onClick={downloadFile}
          variant="outlined"
          style={{ marginRight: '2rem' }}
          startIcon={<CloudDownloadIcon />}
        >
          Baixar arquivo
        </ButtonHeaderPdf>
      </DialogHeaderPdf>
      <div ref={ref} style={{ overflow: 'auto' }}>
        <Box display="flex" justifyContent={'center'} pt={8}>
          <Document file={file?.fileForm} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from({ length: numPages }, (_, index) => (
              <div style={{ marginBottom: '16px' }}>
                <Page
                  width={1074}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </div>
            ))}
          </Document>
          <RegionCountPagePdf>
            <Typography variant="body2">Página {actualPage}</Typography>
          </RegionCountPagePdf>
        </Box>
      </div>
    </Dialog>
  );
};

export default PdfView;
