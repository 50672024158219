import React from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const FIRST = 0;

type Event = React.MouseEvent<HTMLButtonElement, MouseEvent>;

interface Props {
  count?: number;
  page?: number;
  rowsPerPage?: number;
  disabled: boolean;
  onChangePage(event: Event | any, page: number): void;
}

const TablePaginationActions: React.FC<Props> = ({
  count = 0,
  page = 0,
  rowsPerPage = 0,
  disabled = false,
  onChangePage = () => {},
}) => {
  const handleFirstPageButtonClick = (event: Event) => {
    onChangePage(event, FIRST);
  };

  const handleBackButtonClick = (event: Event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: Event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: Event) => {
    onChangePage(event, Math.max(FIRST, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box flexShrink={0} ml={2.5}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === FIRST || disabled}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === FIRST || disabled}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || disabled}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || disabled}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

export default TablePaginationActions;
