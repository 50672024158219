import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      paddingLeft: '24px',
      fontWeight: 'bold',
      paddingTop: '16px',
      paddingBottom: '0px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      fontWeight: 600,
    },
    subTitle: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  });

export interface DialogTitle {
  title: string;
  subtitle: string;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitleElement = withStyles(styles)((props: DialogTitleProps) => {
  const { title, subTitle, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      {subTitle && (
        <Typography className={classes.subTitle} variant="body2">
          {subTitle}
        </Typography>
      )}
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

interface CustomizedDialogsProps extends DialogProps {
  onClose?: () => void;
  title?: string;
  subTitle?: string;
  open: boolean;
}

const CustomizedDialogs: React.FC<CustomizedDialogsProps> = ({
  title,
  subTitle,
  onClose,
  children,
  ...rest
}) => {
  return (
    <div>
      <Dialog maxWidth={rest.maxWidth ? rest.maxWidth : 'md'} {...rest}>
        {title && (
          <DialogTitleElement
            title={title}
            subTitle={subTitle}
            onClose={onClose}
          >
            {children}
          </DialogTitleElement>
        )}
        {children}
      </Dialog>
    </div>
  );
};

export default CustomizedDialogs;
