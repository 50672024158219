import Box from '@material-ui/core/Box';
import { EnvelopStatusCount } from '@src/models/stores/signatures/envelope-status';
import React from 'react';
import Dashcard from '../Dashcard';
import {
  EnvelopeCheckIcon,
  EnvelopeStatusContent,
  EnvelopeStatusLabel,
  EnvelopeStatusSelected,
  EnvelopeStatusTotal,
} from './style';
import Skeleton from '@components/Skeleton/Wrapper';
import { envelopeColors } from '../../models/color';

interface EnvelopeStatusSectionProps {
  isLoading?: boolean;
  status: EnvelopStatusCount[];
  selectedStatus: EnvelopStatusCount | undefined;
  onSelectStatus: (id?: string) => void;
}

const EnvelopeStatusSection: React.FC<EnvelopeStatusSectionProps> = ({
  children,
  status,
  isLoading,
  selectedStatus,
  onSelectStatus,
}) => {
  return (
    <Box mt={7}>
      <EnvelopeStatusSelected variant="button">
        {selectedStatus ? selectedStatus.label : 'TODOS'}
      </EnvelopeStatusSelected>
      <Box display={'flex'} mt={2} style={{ gap: '8px' }}>
        {children}
        <Dashcard
          color="#B0B7BD"
          id="dashcard-todos"
          onClick={() => onSelectStatus()}
        >
          <EnvelopeStatusContent>
            <EnvelopeStatusLabel variant="overline">
              TODOS
              {!selectedStatus && (
                <EnvelopeCheckIcon fill="#B0B7BD"></EnvelopeCheckIcon>
              )}
            </EnvelopeStatusLabel>
          </EnvelopeStatusContent>
        </Dashcard>
        {!isLoading ? (
          status.map((status) => (
            <Dashcard
              id={`dashcard-${status.id}`}
              key={status.id}
              onClick={() => onSelectStatus(status.id)}
              color={envelopeColors[status.id]}
            >
              <EnvelopeStatusContent>
                <EnvelopeStatusTotal
                  variant="h6"
                  backgroundColor={envelopeColors[status.id]}
                >
                  {status.total}
                  {selectedStatus && selectedStatus.id === status.id && (
                    <EnvelopeCheckIcon color={envelopeColors[status.id]} />
                  )}
                </EnvelopeStatusTotal>
                <EnvelopeStatusLabel variant="overline">
                  {status.label}
                </EnvelopeStatusLabel>
              </EnvelopeStatusContent>
            </Dashcard>
          ))
        ) : (
          <Box display="flex" style={{ gap: '8px' }}>
            {Array.from({ length: 4 })}
            {Array.from({ length: 4 }).map((_, index) => {
              return (
                <Dashcard color="#EAEAEA" id={`skeleton-${index}`} key={index}>
                  <EnvelopeStatusContent>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      height={1}
                      style={{ gap: 8 }}
                    >
                      <Skeleton width={20} variant="text" />
                      <Skeleton width={80} variant="text" />
                    </Box>
                  </EnvelopeStatusContent>
                </Dashcard>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EnvelopeStatusSection;
