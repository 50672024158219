import React from 'react';

import Button from '@material-ui/core/Button';
import FilterList from '@material-ui/icons/FilterList';

export default function FilterButton({ onClick }) {
  return (
    <Button
      id="filter-button"
      onClick={onClick}
      color="primary"
      startIcon={<FilterList />}
    >
      Filtros
    </Button>
  );
}
