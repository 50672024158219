import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface OverflowTipProps extends TypographyProps {
  value: string | JSX.Element | undefined;
  customStyle?: React.CSSProperties;
}

const OverflowTip: React.FC<OverflowTipProps> = ({
  value,
  noWrap,
  customStyle,
  ref,
  ...rest
}) => {
  const textElementRef = useRef<HTMLDivElement | undefined>();

  const compareSize = () => {
    if (textElementRef && textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={value ?? ''}
      interactive
      disableHoverListener={!hoverStatus}
      style={{ fontSize: '2em' }}
    >
      <Typography
        {...rest}
        ref={textElementRef as any}
        noWrap
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...customStyle,
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTip;
