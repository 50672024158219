import styled, { css } from 'styled-components';

export const Container = styled.div`
  .appbar {
    box-shadow: 0px 3px 6px #00000033;

    .topbar {
      justify-content: space-between;
      flex-direction: row;

      .item {
        display: flex;
        align-items: center;
      }

      .logo-img {
        height: 36px;
        width: auto;
        display: flex;
        margin-top: -25px;
      }

      .user {
        display: flex;
        align-items: center;

        .info {
          margin: 0 10px;
        }
      }
    }
  }

  .MuiListItem-root.Mui-selected {
    background: inherit;
  }
`;

export const Content = styled.div`
  margin-top: var(--topBarHeight);
  min-height: calc(100vh - var(--topBarHeight));
  width: 100%;

  @media screen and (min-width: 960px) {
    ${(props) => {
      if (props.expanded) {
        return css`
          width: calc(100% - var(--sidebarWidthClosed));
          margin-left: var(--sidebarWidthClosed);
          transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        `;
      }
      return css`
        width: calc(100% - var(--sidebarWidthOpen));
        margin-left: var(--sidebarWidthOpen);
        transition: all 25ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      `;
    }}
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  margin: auto;
`;
