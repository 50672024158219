import { CLICKS, END, OPEN, SELECT } from '@constants/analytics-action';

const SIGNATURE = '[ASSINATURA]';
const DETAIL = '[DRAWER DETALHES]';

export const EVENTS = {
  CLICK_NEW_SIGNATURE_BUTTON: {
    eventCategory: '[NOVA ASSINATURA]',
    eventAction: CLICKS,
  },
  CLICK_SIGNATURE_STATUS_CARD: {
    eventCategory: (status: string) =>
      `${SIGNATURE}[CARD ${status
        .replaceAll(/[^a-zA-Z0-9\s]+/g, '')
        .toUpperCase()}]`,
    eventAction: CLICKS,
  },
  CLICK_SIGNATURE_UPDATE_CARD: {
    eventCategory: `${SIGNATURE}[CARD ATUALIZAR]`,
    eventAction: CLICKS,
  },
  SELECT_SIGNATURE_FILTER: {
    eventCategory: `${SIGNATURE}[FILTROS]`,
    eventAction: SELECT,
  },
  SELECT_PROFILE_SIGNATURE: {
    eventCategory: '[ENVIO DE ASSINATURA] [STATUS DO ASSINANTE]',
    eventAction: SELECT,
  },
  CLICK_OPEN_SIGNATURE_DETAIL: {
    eventCategory: `${SIGNATURE}${DETAIL}`,
    eventAction: OPEN,
  },
  CLICK_DOWNLOAD_FILE_SIGNATURE_DETAIL: {
    eventCategory: `${SIGNATURE}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Baixar',
  },
  CLICK_DOWNLOAD_ENVELOPE_SIGNATURE_DETAIL: {
    eventCategory: `${SIGNATURE}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Baixar envelope',
  },
  CLICK_SYNC_UNITARY_SIGNATURE_DETAIL: {
    eventCategory: `${SIGNATURE}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Reenviar unitário',
  },
  CLICK_SYNC_GERAL_SIGNATURE_DETAIL: {
    eventCategory: `${SIGNATURE}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Reenviar geral',
  },
  CLICK_CANCEL_SIGNATURE_DETAIL: {
    eventCategory: `${SIGNATURE}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Cancelar',
  },
  CLICK_VIEW_SIGNATURE_DETAIL: {
    eventCategory: `${SIGNATURE}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Visualizar',
  },
  FINISH_ENVELOPE: {
    eventCategory: `[ENVIO DE ASSINATURA][TEMPO]`,
    eventAction: END,
  },
};
