import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalStore } from '@src/models/stores/global';
import { LayoutState } from '@src/models/stores/layout-state';

const initialState: LayoutState = {
  menuIsOpen: true,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: { ...initialState },
  reducers: {
    changeMenu: (state, action: PayloadAction<boolean>) => {
      state.menuIsOpen = action.payload;
    },
  },
});

export const { changeMenu } = layoutSlice.actions;

export const selectMenuIsOpen = (state: GlobalStore) => state.layout.menuIsOpen;

export default layoutSlice.reducer;
